import * as React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function LinkIdModal({
  linkIdModal,
  changeLinkModalState,
  socailIcon,
  cookieName,
  cookieName2,
  setsendMessageModal,
  pricingModal,
  getCookie,
}) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    changeLinkModalState();
  };

  useEffect(() => {
    if (pricingModal) {
      var cookie = getCookie(cookieName);
      var cookie2 = getCookie(cookieName2);
      if (cookie && !cookieName2) {
        setsendMessageModal(true);
      } else if (cookie && cookie2) {
        setsendMessageModal(true);
      }
    }
  }, [document.cookie, pricingModal]);

  return (
    <div>
      <Dialog
        open={linkIdModal}
        onClose={handleClose}
        id="social-platforms-dms"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ minWidth: "400px" }}
        PaperProps={{
          sx: { borderRadius: "15px", minWidth: "400px", textAlign: "center" },
        }}
      >
        <div className="cross-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <img
          src={
            socailIcon === "twitter"
              ? "./Assets/Images/landing/socail/twitter.webp"
              : socailIcon === "tiktok"
              ? "./Assets/Images/tiktokLink.png"
              : socailIcon === "soundclooud"
              ? "./Assets/Images/sound.png"
              : "./Assets/Images/Instragramicon.png"
          }
          alt=""
          className="linkImage"
        />
        <Link
          className="btn-background btn-main social-Button mb-2 download-extinction-href"
          style={{ textDecoration: "none" }}
          to="https://chromewebstore.google.com/detail/findsocial-login-extensio/lffkapidlaihpombjliljccbcjkcenki?hl=en-US&utm_source=ext_sidebar"
          target="_blank"
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setTimeout(() => {
                window.addEventListener("focus", function () {
                  window.location.reload();
                });
              }, 2000);
            }}
          >
            Download extension
          </div>
        </Link>
      </Dialog>
    </div>
  );
}
