import React, { useEffect } from "react";
import { Select, Space } from "antd";

export const SelectComponent = ({
  disabled,
  PreviousListFilterOnChange,
  PreviousListText,
  data,
  setData,
  filterTextSelect,
}) => {
  useEffect(() => {
    if (PreviousListText?.length) {
      let arr = [
        {
          label: "All", // Ensuring "All" is always first
          value: "All",
        },
        ...PreviousListText.map((province) => ({
          label: province?.search,
          value: province?.search,
        })),
      ];
      setData(arr);
    }
  }, [PreviousListText, setData]);

  return (
    <Space wrap>
      <div className="custom-select">
        <Select
          disabled={disabled}
          defaultValue={data[0]}
          style={{
            width: 220,
            border: "none",
            outline: "none",
            boxShadow: "none",
            opacity: disabled ? 0.5 : 1,
          }}
          showSearch={true}
          value={filterTextSelect}
          key={data.map((province, index) => index)}
          onChange={PreviousListFilterOnChange}
          options={data.sort((a, b) => (a.value === "All" ? -1 : 1))} 
        ></Select>
      </div>
    </Space>
  );
};
