import React, { useState, useEffect, useCallback, useMemo } from "react";
import BreadCrumb from "./BreadCrumb";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { w3cwebsocket } from "websocket";
import { getPreviousData } from "../Data/Api/dashboard";
import { disconnectFromWebsocket } from "../Data/Api/dashboard";
import HeaderUI from "./HeaderUI";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SelectComponent } from "../Components/Select";
import { Processing } from "../Components/Processing";
import DownloadModal from "../Components/downloadModal";
import UpdateModal from "../Components/updateModal";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useDeepSearch } from "../store/useDeepSearch";
import { Modal, Tooltip } from "antd";
import { ExpandableMessageQueue } from "../Components/MessageQueue";
import { useNotification } from "../store/useNotification";

export default function Dashboard({
  isExpire,
  data: data2,
  userData,
  setUserData,
  setApiCall,
  apiCall,
  userInfo,

  apiToken,
  disabledButton,
  setDisabledButton,
  disableSocket,
}) {
  const { deepSearch, setShowModal, selectedRow, showModal } = useDeepSearch();
  const [data, setData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pending, setPending] = useState(true);
  const [downloadPDFModal, setDownloadPDFModal] = useState(false);
  const [downloadCSVModal, setDownloadCSVModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [searchScrappedData, setSearchScrappedData] = useState("");
  const [filterObj, setFilterObj] = useState({
    email: false,
    instagram: false,
    tiktok: false,
  });
  const [filter, setFilter] = useState({
    min_followers: 0,
    min_following: 0,
    max_following: 0,
    min_posts: 0,
    max_posts: 0,
    has_clips: false,
    has_bio_link: false,
    is_private: false,
    is_business_account: false,
    is_professional_account: false,
    is_verified: false,
    has_email: false,
    subscribers: 0,
    email: false,
    instagram: false,
    twitter: false,
    facebook: false,
    tiktok: false,
    soundcloud: false,
    spotify: false,
    other_link: false,
    first_name: false,
    full_name: false,
    other_emails: false,
    followers: 0,
    following: 0,
    likes: 0,
    country_code: "",
    playlist_count: 0,
    playlist_likes_count: 0,
    track_count: 0,
    verified: false,
    monthly_listens: 0,
    rank: 0,
    wikipedia: false,
    creator: "",
    collaborative: false,
    tracks: 0,
    public: false,
    private: false,
    genre: "",
  });

  const isActive = Object?.values(filter)?.some(
    (value) => value === true || value > 0
  );

  const [filterTextclick, setFilterTextclick] = useState("");
  const [filterTextSelect, setFilterTextSelect] = useState("All");
  const customSorttext = "asc";
  const [filteredData, setFilteredData] = useState([]);
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [disableScrapping, setDisableScrapping] = useState(false);
  const [disableScrappingImport, setDisableScrappingImport] = useState(false);
  const [filteredOldData, setFilteredOldData] = useState([]);
  const customSortcount = 1;
  const [searchApi, setSearchApi] = useState(false);
  const [importSearch, setImportSearch] = useState(false);
  const [search, setSearch] = useState(null);
  const [client, setClient] = useState(null);
  const [files, setFiles] = useState("");
  const [dataToggle, setDataToggle] = useState(false);
  const [columns_data_for_export, setColumns_data_for_export] = useState(false);
  const [rows_data_for_export, setRows_data_for_export] = useState(false);
  const navigate = useNavigate();
  const [upgradeModal, setupgradeModal] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [upgradeAfter, setupgradeAfter] = useState("");
  const { isAuthenticated, isLoading } = useAuth0();
  var url = new URL(window.location);
  let open = url.searchParams.get("open");

  useEffect(() => {
    if (!disabledButton) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
        if (getData) {
          if (userInfo) {
            getPreviousData(
              getData?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      } else {
        setData(data2);
        if (data2) {
          if (userInfo) {
            getPreviousData(
              data2?.previousDataUrl(userInfo?.user_id),
              setTableData,
              setPending
            );
          }
        }
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [dataToggle, userInfo, loadData]);

  useEffect(() => {
    if (disableSocket) {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    } else {
      if (typeof data2 === "function") {
        let getData = data2();
        setData(getData);
      } else {
        setData(data2);
      }
    }
  }, [disableSocket]);
  const scrapperCheckFn = () => {
    if (!disabledButton) {
      axios
        .get(data.websocketTestUrl)
        .then((response) => {
          setDisableScrapping(response?.data?.maintenance);
          if (data.name === "Instagram") {
            setDisableScrappingImport(!response?.data?.Success);
          }
        })
        .catch((error) => {
          setDisableScrapping(error.response?.data?.maintenance);

          if (data.name === "Instagram") {
            setDisableScrappingImport(!error?.response?.data?.Success);
          }
        });
    }
    if (data.name === "Instagram") {
      axios
        .get(data.websocketTestListUrl)
        .then((response) => {})
        .catch((error) => {});
    }
  };
  useEffect(() => {
    if (data) {
      scrapperCheckFn();
    }
  }, [data]);
  const handleClose = () => {
    setupgradeModal(false);
  };
  const isOnlySpaces = /^\s*$/;
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/");
    }
  }, [isLoading, isAuthenticated]);
  useEffect(() => {
    if (open === "PDF") {
      setDownloadPDFModal(true);
    } else if (open === "CSV") {
      setDownloadCSVModal(true);
    }
  }, [open]);

  const csvFileToArray = (string) => {
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    let arr = [];
    csvRows.forEach((i) => {
      const values = i.split(",");
      let str = values[0];
      if (typeof str === "string") {
        arr.push(str);
      } else {
        arr.push(JSON.stringify(str));
      }
    });
    let data = arr.join(",");
    setFilterText(data);
    setImportSearch(true);
  };

  useEffect(() => {
    if (files !== "") {
      const fileReader = new FileReader();
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;

        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(files);
    }
  }, [files]);

  const ids = tableData?.filter((o) => o != null).map((o) => o?.search);

  const PreviousListText = useMemo(
    () =>
      ids?.length
        ? ids[0]
          ? tableData?.filter((search, index) =>
              search !== null
                ? !ids?.includes(search?.search, index + 1)
                : false
            )
          : tableData
        : null,
    [tableData, ids]
  );

  const columns = data?.columns;

  const [queueData, setQueueData] = useState([]);
  const [messageQueueData, setMessageData] = useState([]);
  const { isOpenBot, setIsOpenBot, setIsNotification, setMessage } =
    useNotification();

  function Queue(previousclient, userId) {
    previousclient?.close();

    const client = w3cwebsocket(
      `${process.env.REACT_APP_WEBSOCKET}/processing-queue`
    );
    setClient(client);
    client.onopen = () => {
      client.send(
        JSON.stringify({
          user: userId,
        })
      );
    };

    client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      setQueueData(
        dataFromServer?.tasks?.length > 0 ? dataFromServer?.tasks : []
      );
    };
    client.onclose = (e) => {
      client?.close();
    };

    client.onerror = () => {
      client?.close();
    };
  }

  function Message(previousclient, userId) {
    previousclient?.close();
    
    const client = w3cwebsocket(
      `${process.env.REACT_APP_WEBSOCKET}/message-queue`
    );
    setClient(client);
    client.onopen = () => {
      client.send(
        JSON.stringify({
          user_id: userId,
        })
      );
    };

    client.onmessage = (message) => {
      console.log('message', message)
      const dataFromServer = JSON.parse(message.data);
      setMessageData(
        dataFromServer?.tasks?.length > 0 ? dataFromServer?.tasks : []
      );
    };
    client.onclose = (e) => {
      client?.close();
    };

    client.onerror = () => {
      client?.close();
    };
  }


  useEffect(() => {
    if (userData?.user_id) {
      Queue(client, userData?.user_id);
      Message(client, userData?.user_id);
    }
  }, [userData?.user_id]);

  function connectToWebsocket(
    websocketUrl,
    websocketUrlListSearch,
    prevClient,
    setClient,
    setTableData,
    setPending,
    search,
    result_type,
    userData,
    setUserData,
    filterObj,
    filtering,
    setFilters,
    apiToken,
    importSearch,
    setImportSearch,
    setSearch,
    setDisabledButton
  ) {
    let count = userData?.user_metadata?.remainingCredits;
    let emailcheck = document.getElementById("email");
    let instagramcheck = document.getElementById("instagram");
    if (data.name === "YouTube") {
      let tiktokcheck = document.getElementById("tiktok");
      tiktokcheck.checked = false;
      setFilterObj({
        email: false,
        instagram: false,
        tiktok: false,
      });
    }
    if (
      data.name !== "Instagram" ||
      data.name !== "Twitter" ||
      data.name !== "SoundCloud"
    ) {
      if (instagramcheck?.checked) {
        instagramcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
    }
    if (data.name !== "Spotify artists") {
      if (emailcheck.checked) {
        emailcheck.checked = false;
        setFilterObj({
          email: false,
          instagram: false,
          tiktok: false,
        });
      }
    }

    prevClient?.close();
    const client = w3cwebsocket(
      importSearch ? websocketUrlListSearch : websocketUrl
    );

    client.onopen = () => {
      setClient(client);
      let filters =
        data.name === "Instagram"
          ? {
              ...(filtering?.min_followers && {
                min_followers: !isNaN(Number(filtering?.min_followers))
                  ? Number(filtering?.min_followers)
                  : 0,
              }),
              ...(filtering?.max_followers && {
                max_followers: !isNaN(Number(filtering?.max_followers))
                  ? Number(filtering?.max_followers)
                  : 0,
              }),
              ...(filtering?.min_posts && {
                min_posts: !isNaN(Number(filtering?.min_posts))
                  ? Number(filtering?.min_posts)
                  : 0,
              }),
              ...(filtering?.max_posts && {
                max_posts: !isNaN(Number(filtering?.max_posts))
                  ? Number(filtering?.max_posts)
                  : 0,
              }),

              ...(filtering?.has_clips && { has_clips: filtering?.has_clips }),
              ...(filtering?.has_bio_link && {
                has_bio_link: filtering?.has_bio_link,
              }),
              ...(filtering?.is_private && {
                is_private: filtering?.is_private,
              }),
              ...(filtering?.is_business_account && {
                is_business_account: filtering?.is_business_account,
              }),
              ...(filtering?.is_professional_account && {
                is_professional_account: filtering?.is_professional_account,
              }),
              ...(filtering?.is_verified && {
                is_verified: filtering?.is_verified,
              }),
              ...(filtering?.has_email && { has_email: filtering?.has_email }),
            }
          : data.name === "Spotify playlists"
          ? {
              ...(filtering?.likes && {
                likes: !isNaN(Number(filtering?.likes))
                  ? Number(filtering.likes)
                  : 0,
              }),
              ...(filtering?.tracks && {
                tracks: !isNaN(Number(filtering?.tracks))
                  ? Number(filtering.tracks)
                  : 0,
              }),
              ...(filtering?.creator && { creator: filtering.creator || "" }),
              ...(filtering?.email && { email: filtering.email }),
              ...(filtering?.instagram && { instagram: filtering.instagram }),
              ...(filtering?.collaborative && {
                collaborative: filtering.collaborative,
              }),
              ...(filtering?.collaborative && {
                collaborative: filtering.collaborative,
              }),
              ...(filtering?.genre && {
                genre: filtering.genre,
              }),
              ...(filtering?.public && { public: filtering.public }),
              ...(filtering?.private && { private: filtering?.private }),
            }
          : data.name === "YouTube"
          ? {
              ...(filtering?.subscribers && {
                subscribers: !isNaN(Number(filtering?.subscribers))
                  ? Number(filtering.subscribers)
                  : 0,
              }),
              ...(filtering?.email && { email: filtering.email }),
              ...(filtering?.instagram && { instagram: filtering.instagram }),
              ...(filtering?.twitter && { twitter: filtering.twitter }),
              ...(filtering?.facebook && { facebook: filtering.facebook }),
              ...(filtering?.tiktok && { tiktok: filtering.tiktok }),
              ...(filtering?.soundcloud && {
                soundcloud: filtering.soundcloud,
              }),
              ...(filtering?.spotify && { spotify: filtering.spotify }),
              ...(filtering?.other_link && {
                other_link: filtering.other_link,
              }),
            }
          : data.name === "SoundCloud"
          ? {
              ...(filtering?.followers && {
                followers: !isNaN(Number(filtering?.followers))
                  ? Number(filtering.followers)
                  : 0,
              }),
              ...(filtering?.following && {
                following: !isNaN(Number(filtering?.following))
                  ? Number(filtering.following)
                  : 0,
              }),
              ...(filtering?.playlist_count && {
                playlist_count: !isNaN(Number(filtering?.playlist_count))
                  ? Number(filtering.playlist_count)
                  : 0,
              }),

              ...(filtering?.track_count && {
                track_count: !isNaN(Number(filtering?.track_count))
                  ? Number(filtering.track_count)
                  : 0,
              }),
              ...(filtering?.likes && {
                likes: !isNaN(Number(filtering?.likes))
                  ? Number(filtering.likes)
                  : 0,
              }),

              ...(filtering?.country_code && {
                country_code: filtering.country_code,
              }),
              ...(filtering?.first_name && {
                first_name: filtering.first_name,
              }),
              ...(filtering?.full_name && { full_name: filtering.full_name }),
              ...(filtering?.email && { email: filtering.email }),
              ...(filtering?.other_emails && {
                other_emails: filtering.other_emails,
              }),
              playlist_likes_count: !isNaN(
                Number(filtering?.playlist_likes_count)
              )
                ? Number(filtering.playlist_likes_count)
                : 0,
              ...(filtering?.verified && { verified: filtering.verified }),
            }
          : data.name === "Tiktok"
          ? {
              ...(filtering?.following && {
                following: !isNaN(Number(filtering?.following))
                  ? Number(filtering.following)
                  : 0,
              }),
              followers: !isNaN(Number(filtering?.followers))
                ? Number(filtering.followers)
                : 0,
              ...(filtering?.likes && {
                likes: !isNaN(Number(filtering?.likes))
                  ? Number(filtering.likes)
                  : 0,
              }),
              ...(filtering?.email && { email: filtering.email }),
              ...(filtering?.instagram && { instagram: filtering.instagram }),
              ...(filtering?.verified && { verified: filtering?.verified }),
            }
          : data.name === "Spotify artists"
          ? {
              ...(filtering?.followers && {
                followers: !isNaN(Number(filtering?.followers))
                  ? Number(filtering.followers)
                  : 0,
              }),
              ...(filtering?.monthly_listens && {
                monthly_listens: !isNaN(Number(filtering?.monthly_listens))
                  ? Number(filtering.monthly_listens)
                  : 0,
              }),
              ...(filtering?.rank && {
                rank: !isNaN(Number(filtering?.rank))
                  ? Number(filtering.rank)
                  : 0,
              }),

              ...(filtering?.verified && {
                verified: filtering.verified,
              }),
              ...(filtering?.facebook && {
                facebook: filtering.facebook,
              }),
              ...(filtering?.instagram && { instagram: filtering.instagram }),
              ...(filtering?.twitter && { twitter: filtering.twitter }),
              ...(filtering?.wikipedia && {
                wikipedia: filtering.wikipedia,
              }),
            }
          : {};

      if (result_type === null) {
        client.send(
          JSON.stringify({
            user: userData?.user_id,
            search,
            filters: { ...filters },
            deep_search: deepSearch,
          })
        );
      } else {
        client.send(
          JSON.stringify({
            user: userData?.user_id,
            result_type: result_type,
            search,
            filters: { ...filters },
            deep_search: deepSearch,
          })
        );
      }
    };

    let arr = [];
    let timeoutId;

    client.onmessage = (message) => {
      let credits = 0;
      if (count >= credits) {
        prevClient?.close();
        Queue(client, userData?.user_id);
        const dataFromServer = JSON.parse(message.data);
        setMessage(dataFromServer?.message);

        setIsNotification(true);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        if (isOpenBot) {
          setIsOpenBot();
        }

        timeoutId = setTimeout(() => {
          setIsNotification(false);
        }, 3000);
      } else {
        toast.error("You don't have enough credits");
        setPending(false);
        setTableData([]);
        setFilters({
          min_followers: 0,
          min_following: 0,
          max_following: 0,
          min_posts: 0,
          max_posts: 0,
          has_clips: false,
          has_bio_link: false,
          is_private: false,
          is_business_account: false,
          is_professional_account: false,
          is_verified: false,
          has_email: false,
          subscribers: 0,
          email: false,
          instagram: false,
          twitter: false,
          facebook: false,
          tiktok: false,
          soundcloud: false,
          spotify: false,
          other_link: false,
          first_name: false,
          full_name: false,
          other_emails: false,
          followers: 0,
          following: 0,
          likes: 0,
          country_code: "",
          playlist_count: 0,
          playlist_likes_count: 0,
          track_count: 0,
          verified: false,
          monthly_listens: 0,
          rank: 0,
          wikipedia: false,
          creator: "",
          collaborative: false,
          tracks: 0,
          public: false,
        });
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText
        );
      }
    };

    client.onclose = (e) => {
      setPending(false);
      if (!arr?.length) {
      }
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
    };

    client.onerror = (e) => {
      toast.error("Under maintenance / Upgrade in progress");
      setPending(false);
      setImportSearch(false);
      setFilter({
        min_followers: 0,
        min_following: 0,
      });
      disconnectFromWebsocket(
        client,
        setDisabledButton,
        setSearch,
        setFilterText
      );
    };
  }

  useEffect(() => {
    if (data?.name === "SoundCloud") {
      setDisabledButton(true);
      const timeoutId = setTimeout(() => {
        setDisabledButton(false);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [data?.name]);

  useEffect(() => {
    if (search !== null && search !== "") {
      setDisabledButton(true);
      connectToWebsocket(
        data.websocketUrl,
        data.websocketUrlListSearch,
        client,
        setClient,
        setTableData,
        setPending,
        search,
        data.resultType,
        userData,
        setUserData,
        filterObj,
        filter,
        setFilter,
        apiToken,
        importSearch,
        setImportSearch,
        setSearch,
        setDisabledButton
      );
    }
  }, [search]);

  const onButtonClicked = useCallback(
    (search, isExpire) => {
      if (!isOnlySpaces.test(search)) {
        if (userData?.user_metadata?.remainingCredits < 1) {
          setupgradeModal(true);
          toast.error("You don't have enough credits");
          document.getElementById("form-Submit-Search").reset();
        } else {
          setSearch(search);
          setSearchApi(true);
        }
      }
    },
    [data, client]
  );

  const customSort = (rows, selector, direction) => {
    return rows.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);

      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === customSorttext ? comparison * -1 : comparison;
    });
  };
  const [filterdata, setFilterData] = useState([]);
  const filteredItemsArray = [];
  const getfilteredItems = () => {
    setFilteredOldData([]);
    const filteredItemsArrayRowsData = tableData
      ?.filter((person) =>
        filterTextSelect === "" || filterTextSelect === "All"
          ? person?.search
          : person?.search === filterTextSelect
      )
      .map((item, index) => (filteredItemsArray[index] = item?.data));
    let filteredItems = filteredItemsArrayRowsData?.filter(
      (item) =>
        JSON.stringify(item)
          .toLowerCase()
          .indexOf(filterTextclick?.toLowerCase()) !== -1
    );
    let columns_data_for_export = [
      ...(columns?.map((d) => d.name) || []),
      "instagramname",
      "instagramurl",
      "instagrambusiness",
      "instagramcatagory_name",
      "instagramverified",
      "instagramposts",
      "instagramfollowing",
      "instagramfollowers",
      "instagramusername",
    ];
    let change = filteredItems.map((item) => {
      if (item.deep_search && item.deep_search.instagram) {
        const instagramData = item.deep_search.instagram;

        const updatedInstagramData = Object.fromEntries(
          Object.entries(instagramData).map(([key, value]) => [
            key.startsWith("instagram")
              ? key
              : `instagram${key.charAt(0).toLowerCase() + key.slice(1)}`,
            value,
          ])
        );

        item.deep_search.instagram = updatedInstagramData;
      }

      return item;
    });

    let rows_data_for_export = change?.map((d1) => {
      return columns_data_for_export
        ?.map((item) => item.toLowerCase())
        ?.map((d2) => {
          if (d1[d2] !== undefined) {
            return d1[d2];
          } else if (
            d1.deep_search?.instagram &&
            d1.deep_search.instagram[d2] !== undefined
          ) {
            return d1.deep_search.instagram[d2];
          } else {
            return "";
          }
        });
    });

    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);
    setFilteredOldData(filteredItems);
    setResetPaginationToggle(!resetPaginationToggle);
  };

  useEffect(() => {
    getfilteredItems();
  }, [tableData, filterTextSelect]);

  useEffect(() => {
    emailFilter();
  }, [
    filterObj?.email,
    filterObj?.instagram,
    filterObj?.tiktok,
    filteredOldData,
  ]);
  const emailFilter = () => {
    let filter = [];

    const isValid = (value) =>
      value !== null && value !== undefined && value.length !== 0;

    if (filterObj?.email || filterObj?.instagram || filterObj?.tiktok) {
      if (filterObj?.instagram && filterObj?.email && filterObj?.tiktok) {
        filter = filteredOldData.filter(
          (ev) =>
            isValid(ev?.email) || isValid(ev?.instagram) || isValid(ev?.tiktok)
        );
      } else if (filterObj?.instagram && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => isValid(ev?.email) || isValid(ev?.instagram)
        );
      } else if (filterObj?.tiktok && filterObj?.email) {
        filter = filteredOldData.filter(
          (ev) => isValid(ev?.email) || isValid(ev?.tiktok)
        );
      } else if (filterObj?.email) {
        filter = filteredOldData.filter((ev) => isValid(ev?.email));
      } else if (filterObj?.instagram) {
        filter = filteredOldData.filter((ev) => isValid(ev?.instagram));
      } else if (filterObj?.tiktok) {
        filter = filteredOldData.filter((ev) => isValid(ev?.tiktok));
      }
    } else {
      filter = filteredOldData;
    }

    setRows_data_for_export(rows_data_for_export);
    setColumns_data_for_export(columns_data_for_export);
    setFilteredSearchData(filter);
  };

  const onFilterOnClick = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setFilterText(e.target.value);
      setImportSearch(false);
      setFilterTextclick("");
    } else {
      setFilterText(e.target.value);
    }
  };

  const PreviousListFilterOnChange = (e) => {
    setResetPaginationToggle(!resetPaginationToggle);
    if (e === "All") {
      setFilterTextSelect(e);
    } else {
      if (e === "") {
        if (searchApi) {
          setDataToggle(!dataToggle);
          setSearchApi(false);
        }
        setFilterTextSelect(e);
      } else {
        setFilterTextSelect(e);
      }
    }
  };

  const filterSearchHandle = () => {
    const filter = filteredSearchData.filter((item) =>
      Object.keys(item).some((key) => {
        const value = item[key];
        return (
          typeof value === "string" &&
          value.toLowerCase().includes(searchScrappedData.trim().toLowerCase())
        );
      })
    );

    setFilteredData(filter);
  };

  useEffect(() => {
    filterSearchHandle();
  }, [filteredData]); // loop issue

  const isFilter = true;
  const download_pdf = () => {
    if (!disabledButton) {
      const doc =
        data.name === "YouTube"
          ? new jsPDF("p", "mm", [1200, 1200], {
              orientation: "landscape",
            })
          : new jsPDF("p", "mm", [1200, 1200], {
              orientation: "landscape",
              floatPrecision: true,
            });

      let obj = {};
      for (let i = 0; i < filteredOldData?.length; i++) {
        obj[i] = { cellWidth: 50 };
      }

      doc.autoTable({
        head: [columns_data_for_export],
        body: rows_data_for_export,
        columnStyles: obj,
      });

      doc.save(
        `${data.name}-${
          filterTextSelect === "" || filterTextSelect === "All"
            ? "select-all"
            : filterTextSelect
        }-${new Date()}.pdf`
      );
      setDownloadPDFModal(false);
    }
  };

  const onChangeRoute = () => {
    navigate(
      `/subscription-plan?redirect=${data.name.toLowerCase()}-dashboard${
        upgradeAfter !== "" ? `&open=${upgradeAfter}` : ""
      }`
    );
  };

  useEffect(() => {
    return () => {
      if (client?.readyState === 1) {
        setImportSearch(false);
        disconnectFromWebsocket(
          client,
          setDisabledButton,
          setSearch,
          setFilterText,
          true
        );
      }
    };
  }, [client]);

  const customStyles = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleContent = () => {
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    setIsOpen5(false);
    setIsOpen(!isOpen);
  };
  const toggleContent2 = () => {
    setIsOpen4(false);

    setIsOpen(false);
    setIsOpen3(false);
    setIsOpen5(false);
    setIsOpen2(!isOpen2);
  };
  const toggleContent3 = () => {
    setIsOpen4(false);
    setIsOpen5(false);

    setIsOpen2(false);

    setIsOpen(false);

    setIsOpen3(!isOpen3);
  };
  const toggleContent4 = () => {
    setIsOpen3(false);

    setIsOpen2(false);
    setIsOpen5(false);

    setIsOpen(false);

    setIsOpen4(!isOpen4);
  };
  const toggleContent5 = () => {
    setIsOpen3(false);

    setIsOpen2(false);

    setIsOpen(false);

    setIsOpen4(false);
    setIsOpen5(!isOpen5);
  };
  return (
    <>
      <ExpandableMessageQueue
        item={queueData}
        message={messageQueueData}
        setIsOpen={setIsOpenBot}
        isOpen={isOpenBot}
        setFilterTextSelect={setFilterTextSelect}
      />
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="margin-left-right">
        <BreadCrumb page={data.name} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
            <h1>Dashboard</h1>
          </div>
        </div>

        <FilterComponent
          disableScrapping={disableScrapping}
          disableScrappingImport={disableScrappingImport}
          data={data}
          isActive={isActive}
          onFilter={onFilterOnClick}
          filterText={filterText}
          setFiles={setFiles}
          setSearch={setSearch}
          setFilterText={setFilterText}
          filterTextclick={onButtonClicked}
          isExpire={isExpire}
          client={client}
          disabledButton={disableSocket || disabledButton}
          disconnectFromWebsocket={disconnectFromWebsocket}
          setDisabledButton={setDisabledButton}
          files={files}
          setFilterObj={setFilter}
          filterObj={filter}
          platform={data.name}
          userData={userData}
          importSearch={importSearch}
          setImportSearch={setImportSearch}
        />

        <div className="row container-fuild-margin">
          <span className="input-text-check-box">
            <input
              type="text"
              placeholder="Search"
              className="mass-input"
              value={searchScrappedData}
              disabled={disabledButton}
              onChange={(ev) => {
                if (isOnlySpaces.test(ev.target.value)) {
                  setSearchScrappedData("");
                  getfilteredItems();
                } else if (ev.target.value === "") {
                  setSearchScrappedData(ev.target.value);
                  getfilteredItems();
                } else {
                  setSearchScrappedData(ev.target.value);
                }
              }}
              id="input-filed"
            />
            <div className="checkBoxes">
              {data.name === "Spotify artists" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    type="checkbox"
                    id="email"
                    className="checkBox"
                    name="remember"
                    disabled={disabledButton}
                    value="1"
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          email: !filterObj?.email,
                        });
                      }
                    }}
                  />
                  <label for="email">Show results with emails</label>
                </div>
              )}
              {data.name === "Instagram" ||
              data.name === "Twitter" ||
              data.name === "SoundCloud" ? null : (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          instagram: !filterObj?.instagram,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="instagram"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="instagram">
                    Show results with instagram profiles
                  </label>
                </div>
              )}
              {data.name === "YouTube" ? (
                <div
                  className="form-group"
                  style={disabledButton ? { opacity: 0.5 } : { opacity: 1 }}
                >
                  <input
                    onClick={() => {
                      if (!disabledButton) {
                        setFilterObj({
                          ...filterObj,
                          tiktok: !filterObj?.tiktok,
                        });
                      }
                    }}
                    disabled={disabledButton}
                    type="checkbox"
                    id="tiktok"
                    className="checkBox"
                    name="terms_and_conditions"
                    value="1"
                  />
                  <label for="tiktok">Show results with Tiktok profiles</label>
                </div>
              ) : null}
            </div>
          </span>
          <div className="col-lg-12 col-md-12 col-sm-12 container-padding">
            <div className={"filter-div"}>
              <SelectComponent
                PreviousListText={PreviousListText}
                PreviousListFilterOnChange={PreviousListFilterOnChange}
                disabled={disabledButton}
                data={filterdata?.reverse()}
                setData={setFilterData}
                isFilter={isFilter}
                filterTextSelect={filterTextSelect}
              />
              <Tooltip placement="topLeft" title={"Refresh Data"} arrow={true}>
                <button
                  onClick={() => setLoadData(!loadData)}
                  className="hover:bg-slate-50 rounded-full p-1"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 512 512"
                    height="1.5em"
                    width="1.5em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 388c-72.597 0-132-59.405-132-132 0-72.601 59.403-132 132-132 36.3 0 69.299 15.4 92.406 39.601L278 234h154V80l-51.698 51.702C348.406 99.798 304.406 80 256 80c-96.797 0-176 79.203-176 176s78.094 176 176 176c81.045 0 148.287-54.134 169.401-128H378.85c-18.745 49.561-67.138 84-122.85 84z"></path>
                  </svg>
                </button>
              </Tooltip>
            </div>

            <DataTable
              data={filteredData}
              columns={columns}
              customStyles={customStyles}
              noDataComponent={
                <div className="nothing-to-show">
                  <img
                    style={{ height: "360px" }}
                    src="./Assets/Images/components/sendAnimation.svg"
                    alt="root"
                  />
                </div>
              }
              pagination={!disabledButton}
              paginationResetDefaultPage={
                resetPaginationToggle || filterObj?.email || disabledButton
              }
              defaultSortFieldId={customSortcount}
              sortFunction={customSort}
              progressPending={pending}
              progressComponent={
                <Processing
                  extraClass={true}
                  header={"Processing"}
                  text={
                    data.name === "Twitter"
                      ? "It usually takes 60 to 90 seconds to generate data through Twitter."
                      : "Please wait while we are processing your request"
                  }
                />
              }
            />

            <Modal
              open={showModal}
              onCancel={() => {
                setShowModal();

                setIsOpen3(false);

                setIsOpen2(false);

                setIsOpen(false);

                setIsOpen4(false);
                setIsOpen5(false);
              }}
              footer={null}
              className="deepModal"
            >
              {selectedRow?.instagram ? (
                <div className="border-filter" style={{ marginTop: 10 }}>
                  <div
                    onClick={toggleContent}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Instagram</div>
                    <div>
                      {isOpen ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen && (
                    <div
                      style={{
                        marginTop: 16,
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                      }}
                    >
                      {selectedRow?.instagram?.instagramname && (
                        <div>
                          <strong>Name: </strong>
                          {selectedRow?.instagram?.instagramname}
                        </div>
                      )}
                      {selectedRow?.instagram?.instagramusername && (
                        <div>
                          <strong>Username: </strong>
                          {selectedRow?.instagram?.instagramusername}
                        </div>
                      )}

                      {selectedRow?.instagram?.instagramemail?.[0] && (
                        <div>
                          <strong>Email: </strong>
                          {selectedRow?.instagram?.instagramemail?.[0]}
                        </div>
                      )}

                      <div>
                        <strong>Followers: </strong>
                        {selectedRow?.instagram?.instagramfollowers || 0}
                      </div>

                      <div>
                        <strong>Following: </strong>
                        {selectedRow?.instagram?.instagramfollowing || 0}
                      </div>

                      {selectedRow?.instagram?.instagramposts && (
                        <div>
                          <strong>Posts: </strong>
                          {selectedRow?.instagram?.instagramposts}
                        </div>
                      )}

                      <div>
                        <strong>Verified: </strong>
                        {selectedRow?.instagram?.instagramverified === true &&
                          "Yes"}
                        {selectedRow?.instagram?.instagramverified === false &&
                          "No"}
                      </div>

                      {selectedRow?.instagram?.instagramcatagory_name && (
                        <div>
                          <strong>Category: </strong>
                          {selectedRow?.instagram?.instagramcatagory_name}
                        </div>
                      )}
                      <div>
                        <strong>Business Account: </strong>
                        {selectedRow?.instagram?.instagrambusiness === true &&
                          "Yes"}
                        {selectedRow?.instagram?.instagrambusiness === false &&
                          "No"}
                      </div>
                      {selectedRow?.instagram?.instagramurl && (
                        <div>
                          <strong>Profile Link: </strong>
                          {selectedRow?.instagram?.instagramurl ? (
                            <a
                              href={selectedRow?.instagram?.instagramurl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {selectedRow?.instagram?.instagramlink && (
                        <div>
                          <strong>External Link: </strong>
                          {selectedRow?.instagram?.instagramlink ? (
                            <a
                              href={selectedRow?.instagram?.instagramlink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Fan Link
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.tiktok ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent2}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>TikTok</div>
                    <div>
                      {isOpen2 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen2 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.tiktok?.name ? (
                        <div>
                          <strong>Name: </strong>
                          {selectedRow?.tiktok?.name}
                        </div>
                      ) : null}
                      {selectedRow?.tiktok?.uid ? (
                        <div>
                          <strong>Uid: </strong>
                          {selectedRow?.tiktok?.uid}
                        </div>
                      ) : null}
                      {selectedRow?.tiktok?.username ? (
                        <div>
                          <strong>Username: </strong>
                          {selectedRow?.tiktok?.username}
                        </div>
                      ) : null}

                      {selectedRow?.tiktok?.email?.[0] ? (
                        <div>
                          <strong>Email: </strong>
                          <p className="">{selectedRow?.tiktok?.email?.[0]}</p>
                        </div>
                      ) : null}

                      <div>
                        <strong>Following: </strong>
                        {selectedRow?.tiktok?.following || 0}
                      </div>

                      <div>
                        <strong>Followers: </strong>
                        {selectedRow?.tiktok?.followers || 0}
                      </div>
                      <div>
                        <strong>Posts: </strong>
                        {selectedRow?.tiktok?.posts || 0}
                      </div>

                      {selectedRow?.tiktok?.link && (
                        <div>
                          <strong className="mr-1">Other Link: </strong>

                          <a
                            href={selectedRow?.tiktok?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline"
                          >
                            {selectedRow?.tiktok?.link?.link}
                          </a>
                        </div>
                      )}
                      {selectedRow?.tiktok?.url && (
                        <div>
                          <strong className="mr-1">Profile URL:</strong>

                          {selectedRow?.tiktok?.url ? (
                            <a
                              href={selectedRow?.tiktok?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {selectedRow?.tiktok?.description && (
                        <div className="col-span-full">
                          <strong>Description:</strong>
                          {selectedRow?.tiktok?.description}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.soundcloud ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent3}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Soundcloud</div>
                    <div>
                      {isOpen3 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen3 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.soundcloud?.name && (
                        <div>
                          <strong>Name: </strong>{" "}
                          {selectedRow?.soundcloud?.name}
                        </div>
                      )}

                      {selectedRow?.soundcloud?.email?.[0] && (
                        <div>
                          <strong>Email: </strong>
                          {selectedRow?.soundcloud?.email[0]}
                        </div>
                      )}
                      <div>
                        <strong>Followers: </strong>
                        {selectedRow?.soundcloud?.followers || 0}
                      </div>

                      <div>
                        <strong>Following: </strong>
                        {selectedRow?.soundcloud?.following || 0}
                      </div>
                      <div>
                        <strong>Likes: </strong>
                        {selectedRow?.soundcloud?.likes || 0}
                      </div>
                      <div>
                        <strong>Verified: </strong>
                        {selectedRow?.soundcloud?.verified ? "Yes" : "No"}
                      </div>

                      {selectedRow?.soundcloud?.url && (
                        <div>
                          <strong className="mr-1">Profile URL:</strong>

                          {selectedRow?.soundcloud?.url ? (
                            <a
                              href={selectedRow?.soundcloud?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.spotify ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent4}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>Spotify Artist</div>
                    <div>
                      {isOpen4 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen4 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.spotify?.name && (
                        <div>
                          <strong>Name: </strong> {selectedRow?.spotify?.name}
                        </div>
                      )}

                      <div>
                        <strong>Followers: </strong>
                        {selectedRow?.spotify?.followers || 0}
                      </div>

                      {selectedRow?.spotify?.monthly_listens && (
                        <div>
                          <strong>Monthly listens: </strong>
                          {selectedRow?.spotify?.monthly_listens}
                        </div>
                      )}
                      {selectedRow?.spotify?.wikipedia && (
                        <div>
                          <strong>Wikipedia: </strong>
                          {selectedRow?.spotify?.wikipedia}
                        </div>
                      )}
                      {selectedRow?.spotify?.url && (
                        <div>
                          <strong className="mr-1">Profile URL: </strong>

                          {selectedRow?.spotify?.url ? (
                            <a
                              href={selectedRow?.spotify?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
              {selectedRow?.youtube ? (
                <div className="border-filter">
                  <div
                    onClick={toggleContent5}
                    style={{
                      cursor: "pointer",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <div>YouTube</div>
                    <div>
                      {isOpen5 ? (
                        <svg
                          width="10"
                          height="2"
                          viewBox="0 0 10 2"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                            fill="#262626"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                            fill="#262626"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen5 && (
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                        marginTop: 16,
                      }}
                    >
                      {selectedRow?.youtube?.data?.name && (
                        <div>
                          <strong>Name: </strong>
                          {selectedRow?.youtube?.data?.name}
                        </div>
                      )}
                      {selectedRow?.youtube?.data?.username && (
                        <div>
                          <strong>Username: </strong>
                          {selectedRow?.youtube?.data?.username}
                        </div>
                      )}
                      {selectedRow?.youtube?.data?.email !== null && (
                        <div>
                          <strong>Email: </strong>
                          {selectedRow?.youtube?.data?.email?.[0]}
                        </div>
                      )}

                      <div>
                        <strong>Subscriber: </strong>
                        {selectedRow?.youtube?.data?.subscribers || 0}
                      </div>
                      <div>
                        <strong>Likes: </strong>
                        {selectedRow?.youtube?.data?.likes || 0}
                      </div>

                      {selectedRow?.youtube?.data?.url && (
                        <div>
                          <strong>Profile URL: </strong>

                          {selectedRow?.youtube?.data?.url ? (
                            <a
                              href={selectedRow?.youtube?.data?.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Profile
                            </a>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      {selectedRow?.youtube?.data?.description && (
                        <div className="col-span-full">
                          <strong>Description: </strong>
                          {selectedRow?.youtube?.data?.description}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div />
              )}
            </Modal>

            <div className="csv-pdf-btns mt-4">
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                // onClick={download_pdf}
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeModal(true);
                    setupgradeAfter("PDF");
                  } else if (!disabledButton) {
                    setDownloadPDFModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download PDF file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                    alt="root"
                  />
                )}
              </button>
              <button
                disabled={filteredData?.length ? disabledButton : true}
                className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width"
                onClick={() => {
                  if (userData?.user_metadata?.subscriptionPlan === "Free") {
                    setupgradeAfter("CSV");
                    setupgradeModal(true);
                  } else if (!disabledButton && filteredData?.length) {
                    setDownloadCSVModal(true);
                  }
                }}
                style={{
                  opacity: disabledButton ? 0.5 : 1,
                  position: "relative",
                }}
              >
                Download CSV file
                {userData?.user_metadata?.subscriptionPlan === "Free" && (
                  <img
                    src="./Assets/Images/premium.png"
                    className="download-premium"
                    alt="root"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <DownloadModal
        handleClose={() => {
          setDownloadCSVModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={true}
        disabledButton={disabledButton}
        rows_data_for_export={rows_data_for_export}
        columns_data_for_export={columns_data_for_export}
        open={downloadCSVModal}
        data={data}
        descriptions={"Click on below button to download CSV file"}
        filterTextSelect={filterTextSelect}
      />
      <DownloadModal
        handleClose={() => {
          setDownloadPDFModal(false);
          navigate(window.location.pathname);
        }}
        csvFile={false}
        descriptions={"Click on below button to download PDF file"}
        open={downloadPDFModal}
        download_pdf={download_pdf}
      />
      <UpdateModal
        show={upgradeModal}
        setupgradeModal={setupgradeModal}
        handleClose={handleClose}
        onNext={onChangeRoute}
      />
    </>
  );
}
