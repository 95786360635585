import React, { useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function Loader() {
  const [percentage, setPercentage] = useState(0);

  const idCSS = "hello";
  setTimeout(() => {
    if (percentage !== 100) {
      setPercentage(percentage + 1);
    }
  }, 35);
  return (
    <div className="loader-spinner-parent">
      <GradientSVG />
      <CircularProgressbarWithChildren
        strokeWidth={8}
        value={percentage}
        styles={{
          path: { stroke: `url(#${idCSS})`, height: "100%" },
          trail: {
            stroke: "#fff",
          },
        }}
      >
        {percentage === 100 && (
          <div className="loader-spinner-children">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height={25}
              width={25}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
            </svg>
          </div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default Loader;
function GradientSVG() {
  const idCSS = "hello";
  const gradientTransform = `rotate(90deg)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="12.66%" stopColor="#01C38D" />
          <stop offset="100%" stopColor="#7728B5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
