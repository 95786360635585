import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonComponent from "../Components/buttonComponent";
import { useAuth0 } from "@auth0/auth0-react";
export default function UpdateModal({ isShow, navigate, apiCall, setApiCall }) {
  const { logout } = useAuth0();
  return (
    <Modal show={isShow} className="subscriptionModal">
      <Modal.Body>
        <div>
          <h3>Warning</h3>
          <p>Your subscription has been expired</p>
          <div className="confirmPayment-button-parent">
            <ButtonComponent
              onClick={() => {
                logout({ logoutParams: { returnTo: window.location.origin } });
                setApiCall(!apiCall);
              }}
              style={{
                height: "40px",
                width: "120px",
                marginLeft: "10px",
                whiteSpace: "pre",
              }}
              text={"Logout"}
              btnclass="btn-background login-Height res-width"
            />
            <Link
              to={"/subscription-plan"}
              style={{ textDecoration: "none" }}
              className="res-width"
            >
              <ButtonComponent
                style={{
                  height: "38px",
                  width: "171px",
                  whiteSpace: "nowrap",
                }}
                text={"Upgrade Plan"}
                btnclass="btn-background res-width"
              />
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
