import React from "react";

function SoundCloudRangeInput({
  value,
  onChange,
  disabledButton,
  placeholder,
  type = "number",
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 10,
        color: "#878787",
        fontSize: "14px",
      }}
    >
      <input
        type={type}
        disabled={disabledButton}
        value={value || "" }
        onChange={onChange}
        placeholder={placeholder || "0"}
        className="filter-menu-input"
      />
    </div>
  );
}

export function SoundCloudFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const fields = [
    { label: "Number of followers", key: "followers", type: "number" },
    { label: "Number of following", key: "following", type: "number" },
    // { label: "Number of likes", key: "likes", type: "number" },
    {
      label: "Number of playlists",
      key: "playlist_count",
      type: "number",
    },
    // {
    //   label: "Playlist likes count",
    //   key: "playlist_likes_count",
    //   type: "number",
    // },
    { label: "	Number of tracks", key: "track_count", type: "number" },
    // { label: "Country Code", key: "country_code", type: "string" },
  ];

  return (
    <div
      style={{
        marginTop: 16,
        alignItems: "center",
        display: "grid",
        gap: 27,
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      {fields.map((field, index) => (
        <div key={index}>
          <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">{field.label}</p>
          </div>
          <div style={{ display: "flex", gap: 17 }}>
            <SoundCloudRangeInput
              label={field.label}
              value={filterObj?.[field.key]}
              onChange={(ev) => {
                const numericValue = Number(ev.target.value);
                if (!isNaN(numericValue) && numericValue >= 0) {
                  setFilterObj({
                    ...filterObj,
                    [field.key]: numericValue,
                  });
                }
              }}
              type={field?.type}
              disabledButton={disabledButton}
              placeholder={field?.type == "string" ? " " : ""}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export function SoundCloudShouldBe({ filterObj, setFilterObj }) {
  const buttonConfig = [
    // { label: "First name", key: "first_name" },
    // { label: "Full name", key: "full_name" },
    // { label: "Email", key: "email" },
    // { label: "Other email", key: "other_emails" },
    { label: "Verified account", key: "verified" },
  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };
  return (
    <div style={{ display: "flex", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
