import { create } from "zustand";

export const useNotification = create((set, get) => ({
  isOpenBot: false,
  isNotification: false,
  message: "",
 
  setIsOpenBot: () => {
    const { isOpenBot } = get();
    set({ isOpenBot: !isOpenBot });
  },

  setIsNotification:(value)=>{
    set({ isNotification: value });
  },

  setMessage:(message)=>{
    set({ message: message });
  }



}));
