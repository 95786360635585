import { Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";

const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const Youtube = (userData, setUserData, disabledButton, handleRowClicked) => {
  console.log(handleRowClicked, "dsd");

  let obj = {
    name: "YouTube",
    url: "youtube-dashboard",
    previousDataUrl: (user) => `/youtube-results/?user=${user}`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/youtube-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/youtube-test-socket`,
    columns: [
      {
        name: "URL",
        selector: (row) => row.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={row.url} arrow={true}>
            <Link
              to={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </Link>
          </Tooltip>
        ),
        style: {
          width: "240px", // override the row height
        },
        value: "url",
        sortable: disabledButton ? false : true,
      },
      {
        name: "",
        selector: (row) => row.deep_search,
        sortable: disabledButton ? true : false,

        cell: (row) =>
          row?.deep_search ? (
            <Tag
              color={"purple"}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClicked(row)}
            >
              Deep Search
            </Tag>
          ) : null,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Username",
        selector: (row) => row.username,
        cell: (row) => <CustomTitle row={row.username} />,
        value: "username",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Subscribers",
        selector: (row) => row.subscribers,
        cell: (row) => <CustomTitle row={row.subscribers} />,
        value: "subscribers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row.email,
        cell: (row) => <CustomTitle row={row.email} />,
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row.instagram,
        cell: (row) => <CustomTitle row={row.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Facebook",
        selector: (row) => row.facebook,
        cell: (row) => <CustomTitle row={row.facebook} />,
        value: "facebook",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Twitter",
        selector: (row) => row.twitter,
        cell: (row) => <CustomTitle row={row.twitter} />,
        value: "twitter",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok",
        selector: (row) => row.tiktok,
        cell: (row) => <CustomTitle row={row.tiktok} />,
        value: "tiktok",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "SoundCloud",
        selector: (row) => row.soundcloud,
        cell: (row) => <CustomTitle row={row.soundcloud} />,
        value: "soundcloud",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Spotify",
        selector: (row) => row.spotify,
        cell: (row) => <CustomTitle row={row.spotify} />,
        value: "spotify",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Other links",
        selector: (row) => row.other_links,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row.other_links?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row.other_links?.length &&
                row.other_links?.map((url, index) => (
                  <Link to={url} className="platforms-Url " target="_blank">
                    {url}
                    {row.other_links?.length - 1 === index ? "" : ","}{" "}
                  </Link>
                ))}
            </div>
          </Tooltip>
        ),
        value: "other_links",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px",
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,

        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px",
        },
      },
    ],
    columnsDeepSearch: [
      {
        name: "URL",
        selector: (row) => row.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={row.url} arrow={true}>
            <Link
              to={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </Link>
          </Tooltip>
        ),
        style: {
          width: "240px",
        },
        value: "url",
        sortable: disabledButton ? false : true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Username",
        selector: (row) => row.username,
        cell: (row) => <CustomTitle row={row.username} />,
        value: "username",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Subscribers",
        selector: (row) => row.subscribers,
        cell: (row) => <CustomTitle row={row.subscribers} />,
        value: "subscribers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row.email,
        cell: (row) => <CustomTitle row={row.email} />,
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row.instagram,
        cell: (row) => <CustomTitle row={row.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Facebook",
        selector: (row) => row.facebook,
        cell: (row) => <CustomTitle row={row.facebook} />,
        value: "facebook",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Twitter",
        selector: (row) => row.twitter,
        cell: (row) => <CustomTitle row={row.twitter} />,
        value: "twitter",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok",
        selector: (row) => row.tiktok,
        cell: (row) => <CustomTitle row={row.tiktok} />,
        value: "tiktok",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "SoundCloud",
        selector: (row) => row.soundcloud,
        cell: (row) => <CustomTitle row={row.soundcloud} />,
        value: "soundcloud",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Spotify",
        selector: (row) => row.spotify,
        cell: (row) => <CustomTitle row={row.spotify} />,
        value: "spotify",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Other links",
        selector: (row) => row.other_links,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row.other_links?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row.other_links?.length &&
                row.other_links?.map((url, index) => (
                  <Link to={url} className="platforms-Url " target="_blank">
                    {url}
                    {row.other_links?.length - 1 === index ? "" : ","}{" "}
                  </Link>
                ))}
            </div>
          </Tooltip>
        ),
        value: "other_links",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,

        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },

      {
        name: "Instagram Name",
        selector: (row) => row?.deep_search?.instagram?.username,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.username} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram URL",
        selector: (row) => row?.deep_search?.instagram?.url,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.url} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Followers",
        selector: (row) => row?.deep_search?.instagram?.followers,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.followers} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Following",
        selector: (row) => row?.deep_search?.instagram?.following,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.following} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Posts",
        selector: (row) => row?.deep_search?.instagram?.posts,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.posts} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok Name",
        selector: (row) => row?.deep_search?.tiktok?.username,
        cell: (row) => <CustomTitle row={row?.deep_search?.tiktok?.username} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok URL",
        selector: (row) => row?.deep_search?.tiktok?.url,
        cell: (row) => <CustomTitle row={row?.deep_search?.tiktok?.url} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok Followers",
        selector: (row) => row?.deep_search?.tiktok?.followers,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.tiktok?.followers} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
    ],
  };
  return obj;
};
export { Youtube };

export default Youtube;
