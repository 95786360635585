import React from "react";
import DeepSearch from "./filter/deepSearch";
import AccountFollower from "./filter/accountFollower";
import AccountShould from "./filter/accountShould";
import PlaylistPreferenceAccount from "./filter/playlistPreference";

function FilterCards({
  onClose,
  isToggled,
  setIsToggled,
  platform,
  disabledButton,
  filterObj,
  setFilterObj,
  setDeepSearchFalse,
}) {
  return (
    <div className="filter-menu-item relative">
      <button onClick={onClose} className="absolute right-2 top-1.5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </button>

      {platform !== "Spotify playlists" && <DeepSearch platform={platform} />}
      <AccountFollower
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        platform={platform}
        disabledButton={disabledButton}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
      />
        {platform == "Spotify playlists" && (
          <PlaylistPreferenceAccount
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            platform={platform}
            disabledButton={disabledButton}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
          />
        )}
      <AccountShould
        isToggled={isToggled}
        setIsToggled={setIsToggled}
        platform={platform}
        disabledButton={disabledButton}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 15,
          marginTop: 40,
        }}
      >
        <button
          onClick={() => {
            onClose();
            setDeepSearchFalse(false);
            setFilterObj({
              min_followers: 0,
              min_following: 0,
              max_following: 0,
              min_posts: 0,
              max_posts: 0,
              has_clips: false,
              has_bio_link: false,
              is_private: false,
              is_business_account: false,
              is_professional_account: false,
              is_verified: false,
              has_email: false,
              subscribers: 0,
              email: false,
              instagram: false,
              twitter: false,
              facebook: false,
              tiktok: false,
              soundcloud: false,
              spotify: false,
              other_link: false,
              first_name: false,
              full_name: false,
              other_emails: false,
              followers: 0,
              following: 0,
              likes: 0,
              country_code: "",
              playlist_count: 0,
              playlist_likes_count: 0,
              track_count: 0,
              verified: false,
              monthly_listens: 0,
              rank: 0,
              wikipedia: false,
              creator: "",
              collaborative: false,
              tracks: 0,
              public: false,
              genre: "",
            });
          }}
          className="flaot-import-button flaot-border-radius float-button  download-btn-width  cancel"
          style={{
            fontSize: 14,
            border: 2,
            color: "#5C6C84",
            background: "white",
          }}
        >
          Reset Filter
        </button>
        <button
          onClick={onClose}
          className="flaot-import-button flaot-border-radius float-button btn-background download-btn download-btn-width apply"
        >
          Apply Filter
        </button>
      </div>
    </div>
  );
}

export default FilterCards;
