import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import InprogressComponent from "../Components/inprogressComponent";
import AuthSwiper from "./authSwiper";
import InputComponent from "../Components/inputComponent";
import ButtonComponent from "../Components/buttonComponent";
import GoogleButton from "../Components/googleButton";
import FacebookButton from "../Components/facebookButton";
import { useAuth0 } from "@auth0/auth0-react";
function AuthenticateComponent({ userData }) {
  const [loader] = useState(false);
  const { isAuthenticated, isLoading } = useAuth0();

  const navigation = useNavigate();

  const processHash = async () => {
    if (userData?.user_metadata?.subscriptionPlan) {
      navigation("/home");
    } else if (!userData?.user_metadata?.subscriptionPlan) {
      navigation("/subscription-plan");
    }
  };
  useEffect(() => {
    if (!isLoading && isAuthenticated && userData?.user_id) {
      document.cookie = "username=" + userData?.user_id;
      processHash();
    } else if (!isAuthenticated && !isLoading) {
      navigation("/");
    }
  }, [isLoading, userData, isAuthenticated]);
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        <div className="section-2">
          <div className="form-main">
            <div className="back-arrow-text">
              <div>
                <img src="/Assets/Images/back.png" alt="" />
              </div>
              <div>Back</div>
            </div>
            <div className="text-innerText">
              <div className="header-Text">Login</div>
              <div className="header-inner-text">
                Please fill the form below to recieve a qoute for your project.{" "}
                <br /> Fell free to add detail as much as needed
              </div>
            </div>
            <div className="inner-form-main">
              <div className="socail-parent">
                <GoogleButton handleChange={() => {}} />
                <FacebookButton handleChange={() => {}} />
              </div>
              <div className="header-text-center"> Or </div>
              <form style={{ width: "100%" }}>
                <InputComponent
                  heading={"Email"}
                  classinput={"inputBg"}
                  placeholder={"Email address"}
                  type={"email"}
                  icon={"https://img.icons8.com/ios/50/filled-message.png"}
                  required={true}
                />
                <InputComponent
                  heading={"Password"}
                  classinput={"inputBg"}
                  placeholder={"Password"}
                  type={"password"}
                  icon={
                    "https://img.icons8.com/material-outlined/24/visible--v1.png"
                  }
                  required={true}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p>
                    <Link className="account"> Don't have an account?</Link>
                  </p>
                  <Link className="forget-password">Forget password?</Link>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <ButtonComponent
                    text={"Login"}
                    btnclass="btn-background mb-2"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
      <form
        className="form-main"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <InprogressComponent
          headerText={"Authenticating Your Account"}
          paraText={"Please wait while the page is loading."}
          loader={loader}
        />
      </form>
    </React.Fragment>
  );
}

export default AuthenticateComponent;
