import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

function ModalUI({ show, handleClose, handleShow, navigation }) {
  const [artists, setArtists] = useState(false);
  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} className="modal-show-shopify">
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mobel-float">
                  <Button className="mobel-button-close" onClick={handleClose}>
                    <i className="fa-solid fa-xmark mobel-button-i"></i>
                  </Button>
                </div>
                <br />
                <div className="">
                  <div className="select-margin-items spotify">
                    <img
                      src="./Assets/Images/landing/socail/shopify.png"
                      className="rounded-circle model-select"
                      alt=""
                      width="100px"
                      height="100px"
                    />
                    <h5 className="h5-div-heading tabs-a">
                      <br />
                      Spotify
                    </h5>
                  </div>
                  <h1 className="model-search-by">Search</h1>
                  <Link
                    onClick={() => {
                      setArtists(0);
                    }}
                    to={"/spotifyplaylist-dashboard"}
                    className={`model-width-margin-bottom model-heading-h1 ${
                      artists === 0 ? "active-modal-button" : ""
                    } `}
                  >
                    <div className="active-modal-child">
                      <span>Playlist</span>
                    </div>
                  </Link>
                  <Link
                    onClick={() => {
                      setArtists(1);
                    }}
                    to={"/spotifyartists-dashboard"}
                    className={`model-width-margin-bottom model-heading-h1 ${
                      artists === 1 ? "active-modal-button" : ""
                    } `}
                  >
                    <div className="active-modal-child">
                      <span>Artist</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ModalUI;
