import { Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const SpotifyArtists = (disabledButton, handleRowClicked) => {
  let obj = {
    name: "Spotify artists",
    url: "spotifyartists-dashboard",
    previousDataUrl: (user) =>
      `/spotify-results/?user=${user}&result_type=Spotify_Artists`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/spotify-artist-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/spotify-artists-test-socket`,
    resultType: "Spotify_Artists",
    columns: [
      {
        name: "URL",
        selector: (row) => row?.url,
        cell: (row) => {
          return (
            <Tooltip placement="topLeft" title={`${row.url}`} arrow={true}>
              <Link
                to={row?.url}
                className="platforms-Url platform-text-show"
                target="_blank"
              >
                {row?.url}
              </Link>
            </Tooltip>
          );
        },
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "",
        selector: (row) => row.deep_search,
        sortable: disabledButton ? true : false,
        cell: (row) =>
          row?.deep_search ? (
            <Tag
              color={"purple"}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClicked(row)}
            >
              Deep Search
            </Tag>
          ) : null,
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        cell: (row) => <CustomTitle row={row?.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => row?.followers,
        cell: (row) => <CustomTitle row={row?.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Monthly listens",
        selector: (row) => row?.monthly_listens,
        cell: (row) => <CustomTitle row={row?.monthly_listens} />,
        value: "monthly_listens",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Facebook",
        selector: (row) => row?.facebook,
        cell: (row) => <CustomTitle row={row?.facebook} />,
        value: "facebook",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row?.instagram,
        cell: (row) => <CustomTitle row={row?.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Twitter",
        selector: (row) => row?.twitter,
        cell: (row) => <CustomTitle row={row?.twitter} />,
        value: "twitter",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Wikipedia",
        selector: (row) => row?.wikipedia,
        cell: (row) => <CustomTitle row={row?.wikipedia} />,
        value: "wikipedia",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
    ],
  };
  return obj;
};
// export { SoundCloud };

export { SpotifyArtists };
