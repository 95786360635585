import * as React from "react";
import Dialog from "@mui/material/Dialog";
import HorizontalStepper from "./HorizontalStepper";

export default function PricingModal({
  changeUpgradeModal,
  pricingModal,
  userData,
  setApiCall,
  apiCall,
  setUserData,
  openlinkModal,
  handleClose,
}) {
  return (
    <div>
      <Dialog
        open={pricingModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "90vw", maxWidth: "90vw" } }}
      >
        <div className="SubscriptionPlan SubscriptionPlan-modal">
          <h1>Select Your Plan</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
            possimus cumque aliquam laboriosam, <br />
            consequuntur omnis fuga. Sed, non. Laborum, rem officiis!
          </p>
          <HorizontalStepper
            openlinkModal={openlinkModal}
            updateFromModal={true}
            userData={userData}
            setApiCall={setApiCall}
            apiCall={apiCall}
            setUserData={setUserData}
          />
        </div>
      </Dialog>
    </div>
  );
}
