import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default function AuthSwiper() {
  useEffect(() => {
    document.documentElement.style.setProperty("--swiper-theme-color", "#000");
    document.documentElement.style.setProperty(
      "--swiper-navigation-size",
      "16px"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bullet-inactive-color",
      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bottom",
      "-5px"
    );
    document.documentElement.style.setProperty(
      "--swiper-navigation-top-offset",
      "98%"
    );
    document.documentElement.style.setProperty(
      "--swiper-navigation-sides-offset",
      "25%"
    );
  }, []);
  return (
    <Swiper
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="auth-swiper"
    >
      <SwiperSlide>
        <div className="auth-swiper-slider">
          <img src="/Assets/Images/Slider1.png" alt="account" />
          <h1 className="slider-heading">Create Your Account</h1>
          <p className="slider-text">
            Simply connect your Google/Facebook account with just one tap or use
            your own email and password.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="auth-swiper-slider">
          <img src="/Assets/Images/Slider2.png" alt="profile" />
          <h1 className="slider-heading">Manage Your Profile</h1>
          <p className="slider-text">
            Once logged in, you can easily change or manage your profile
            settings within the platform for free.
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="auth-swiper-slider">
          <img src="/Assets/Images/Slider3.png" alt="plan" />
          <h1 className="slider-heading">Choose a Plan</h1>
          <p className="slider-text">
            Choose a plan that fulfills your data needs and start generating
            contact details.
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
