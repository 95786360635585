import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../Components/loginFormx";
import { useState } from "react";
import { webAuth } from "../Data/Auth/webAuth";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthSwiper from "./authSwiper";
function LoginComponent(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [object, setObject] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    document.documentElement.style.setProperty("--swiper-theme-color", "#000");
    document.documentElement.style.setProperty(
      "--swiper-navigation-size",
      "22px"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "#fff"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bullet-inactive-color",
      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)"
    );
  }, []);
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, [localStorage.getItem("accessToken")]);
  const loginUser = async () => {
    webAuth.login(
      {
        realm: `Username-Password-Authentication`,
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
        // username: object?.email,
        email: object?.email,
        password: object?.password,
      },
      function (err, authResult) {
        if (err) {
          setLoader(false);
          return toast.error(err?.error_description || "Invalid error");
        }
        if (authResult) {
          if (authResult && authResult.accessToken) {
            webAuth.client.userInfo(
              authResult.accessToken,
              (error, profile) => {
                if (error) {
                } else {
                  // console.log(profile, "profile");
                  // console.log(authResult, "authResult");
                }
              }
            );
          }
        }
      }
    );
  };
  let testEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleChange = (ev) => {
    ev.preventDefault();
    if (!loader && testEmail.test(object?.email) && object?.password.length) {
      setLoader(true);
      loginUser();
    } else {
      alert("Your email or password is invalid");
    }
  };
  const facebookLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "facebook",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          console.log(err);
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
          // console.log(result);
        }
      }
    );
  };
  const googleLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "google-oauth2",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
        }
      }
    );
  };
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        <div className="section-2">
          <LoginForm
            text={"Login"}
            loader={loader}
            setLoader={setLoader}
            handleChange={handleChange}
            object={object}
            setObject={setObject}
            facebookLogin={facebookLogin}
            googleLogin={googleLogin}
            navigate={navigate}
          />
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginComponent;
