import { Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);

const Tiktok = (userData, setUserData, disabledButton, handleRowClicked) => {
  let obj = {
    name: "Tiktok",
    url: "tiktok-dashboard",
    previousDataUrl: (user) => `/tiktok-results/?user=${user}`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/tiktok-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/tiktok-test-socket`,
    columns: [
      {
        name: "URL",
        selector: (row) => (
          <Tooltip placement="topLeft" title={row.url} arrow={true}>
            <Link
              to={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </Link>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "",
        selector: (row) => row.deep_search,
        sortable: disabledButton ? true : false,
        cell: (row) =>
          row?.deep_search ? (
            <Tag
              color={"purple"}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClicked(row)}
            >
              Deep Search
            </Tag>
          ) : null,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Username",
        selector: (row) => row.username,
        cell: (row) => <CustomTitle row={row.username} />,
        value: "username",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row.email,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row.email?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row.email?.length
                ? row.email?.map((url, index) => (
                    <span>
                      {url}
                      {row.email?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row.instagram,
        cell: (row) => <CustomTitle row={row.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => parseInt(row.followers),
        cell: (row) => <CustomTitle row={parseInt(row.followers)} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,
        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      // {
      //   name: "Send message",
      //   selector: (row) => {
      //     return (
      //       <UpgradeModal
      //         disabledButton={disabledButton}
      //         cookieName={"tiktok_sessionid"}
      //         src="/Assets/Images/components/Sendmsg.png"
      //         name={"Tiktok"}
      //         socailIcon={"tiktok"}
      //         premium={
      //           userData?.user_metadata?.subscriptionPlan === "Free" ||
      //           !userData?.user_metadata?.subscriptionPlan
      //             ? true
      //             : false
      //         }
      //         uid={row?.uid}
      //         userData={userData}
      //         chatAPItUrl={`${process.env.REACT_APP_API_URL}/tiktok-send-message`}
      //         setUserData={setUserData}
      //         chatSocketUrl={`${process.env.REACT_APP_WEBSOCKET}/tiktok-send-message`}
      //       />
      //     );
      //   },
      //   value: "Send Message",
      //   sortable: false,
      // },
    ],
    columnsDeepSearch: [
      {
        name: "URL",
        selector: (row) => (
          <Tooltip placement="topLeft" title={row.url} arrow={true}>
            <Link
              to={row.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row.url}
            </Link>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Name",
        selector: (row) => row.name,
        cell: (row) => <CustomTitle row={row.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Username",
        selector: (row) => row.username,
        cell: (row) => <CustomTitle row={row.username} />,
        value: "username",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row.email,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row.email?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row.email?.length
                ? row.email?.map((url, index) => (
                    <span>
                      {url}
                      {row.email?.length - 1 === index ? "" : ","}{" "}
                    </span>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram",
        selector: (row) => row.instagram,
        cell: (row) => <CustomTitle row={row.instagram} />,
        value: "instagram",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => parseInt(row.followers),
        cell: (row) => <CustomTitle row={parseInt(row.followers)} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "150px", // override the row height
        },
      },
      {
        name: "Description",
        selector: (row) => row.description,
        cell: (row) => <CustomTitle row={row.description} />,
        value: "description",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      // {
      //   name: "Send message",
      //   selector: (row) => {
      //     return (
      //       <UpgradeModal
      //         disabledButton={disabledButton}
      //         cookieName={"tiktok_sessionid"}
      //         src="/Assets/Images/components/Sendmsg.png"
      //         name={"Tiktok"}
      //         socailIcon={"tiktok"}
      //         premium={
      //           userData?.user_metadata?.subscriptionPlan === "Free" ||
      //           !userData?.user_metadata?.subscriptionPlan
      //             ? true
      //             : false
      //         }
      //         uid={row?.uid}
      //         userData={userData}
      //         chatAPItUrl={`${process.env.REACT_APP_API_URL}/tiktok-send-message`}
      //         setUserData={setUserData}
      //         chatSocketUrl={`${process.env.REACT_APP_WEBSOCKET}/tiktok-send-message`}
      //       />
      //     );
      //   },
      //   value: "Send Message",
      //   sortable: false,
      // },
      {
        name: "Instagram Name",
        selector: (row) => row?.deep_search?.instagram?.username,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.username} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram URL",
        selector: (row) => row?.deep_search?.instagram?.url,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.url} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Followers",
        selector: (row) => row?.deep_search?.instagram?.followers,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.followers} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Following",
        selector: (row) => row?.deep_search?.instagram?.following,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.following} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Posts",
        selector: (row) => row?.deep_search?.instagram?.posts,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.posts} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
    ],
  };
  return obj;
};
// const TiktokWithMessage = {
//   name: "Tiktok",
//   previousDataUrl: (user) => `/tiktok-results/?user=${user}`,
//   websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/tiktok-socket/`,
//   columns: [
//     {
//       name: "URL",
//       selector: (row) => row.url,
//       value: "URL",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Name",
//       selector: (row) => row.name,
//       value: "name",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Username",
//       selector: (row) => row.username,
//       value: "username",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "email",
//       selector: (row) => row.email,
//       value: "email",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Instagram",
//       selector: (row) => row.instagram,
//       value: "instagram",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Followers",
//       selector: (row) => parseInt(row.followers),
//       value: "followers",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Description",
//       selector: (row) => row.description,
//       value: "description",
//       sortable: disabledButton ? false : true,
//     },
//     {
//       name: "Send message",
//       selector: () => (
//         <UpgradeModal
// disabledButton = { disabledButton };
//           src="./Assets/Images/components/Send msg.png"
//           premium={true}
//           socailIcon={"tiktok"}
//         />
//       ),
//       value: "Send message",
//       sortable: false,
//     },
//   ],
// };

export { Tiktok };

// const Tiktok = ({ setMessageModal, setMessageInfo }) => {
// 	return {
// 	  name: "Tiktok",
// 	  previousDataUrl: (user) => `/tiktok-results/?user=${user}`,
// 	  websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/tiktok-socket/`,
// 	  columns: [
// 		{
// 		  name: "URL",
// 		  selector: (row) => row.url,
// 		  value: "URL",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "Name",
// 		  selector: (row) => row.name,
// 		  value: "name",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "Username",
// 		  selector: (row) => row.username,
// 		  value: "username",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "email",
// 		  selector: (row) => row.email,
// 		  value: "email",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "Instagram",
// 		  selector: (row) => row.instagram,
// 		  value: "instagram",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "Send message",
// 		  selector: (row) => (
// 			<div
// 			  className="Send-message-parent"
// 			  onClick={() => {
// 				setMessageModal(true);
// 				setMessageInfo(row);
// 			  }}
// 			>
// 			  <img
// 				src={"./Assets/Images/components/Send msg.png"}
// 				// width={60}
// 				alt="Player"
// 			  />
// 			</div>
// 		  ),
// 		  value: "Send message",
// 		  sortable: false,
// 		},
// 		{
// 		  name: "Followers",
// 		  selector: (row) => parseInt(row.followers),
// 		  value: "followers",
// 		  sortable: disabledButton ? false : true,
// 		},
// 		{
// 		  name: "Description",
// 		  selector: (row) => row.description,
// 		  value: "description",
// 		  sortable: disabledButton ? false : true,
// 		},
// 	  ],
// 	};
//   };
//   export default Tiktok;
