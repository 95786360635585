import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import ImageComponent from "./imageComponent";
import { Tooltip } from "antd";

export default function CSVModal({
  setFiles,
  disabledButton,
  disableScrapping,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {disableScrapping ? (
        <Tooltip
          placement="topLeft"
          title={"This platform is under maintenance"}
          className="btn-main btn-background btn-background-tooltip-import"
          arrow={true}
        >
          <ImageComponent src="./Assets/Images/csv.png" />
        </Tooltip>
      ) : (
        <ButtonComponent
          onClick={handleClickOpen}
          disabled={disabledButton}
          src="./Assets/Images/csv.png"
          btnclass="btn-background"
          style={{
            opacity: disabledButton ? 0.5 : 1,
            height: "50px",
            whiteSpace: "nowrap",
            borderRadius: "10px",
          }}
        ></ButtonComponent>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: "400px", textAlign: "center" } }}
      >
        <div className="cross-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          {"Import"}
        </DialogTitle>
        <DialogContent>
          Import a CSV file containing Instagram URLs to find their contact
          details
        </DialogContent>
        <DialogActions>
          <div className="custiomCsvFile import-csv-files mt-2">
            <ButtonComponent
              style={{
                height: "38px",
                width: "100%",
                whiteSpace: "nowrap",
                borderRadius: "10px",
                margin: "10px",
              }}
              text={"Import"}
              btnclass="btn-background"
            />
            <input
              type={"file"}
              typeof={"csv"}
              accept=".csv"
              onChange={(e) => {
                if (e?.target?.files?.length !== 0) {
                  if (e?.target?.files[0].type?.split("/")[1] === "csv") {
                    setFiles(e?.target?.files[0]);
                    setOpen(false);
                  } else {
                    toast.error("Only csv file are supported");
                  }
                }
              }}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
