import React from "react";
import ButtonComponent from "../Components/buttonComponent";
import InputComponent from "../Components/inputComponent";
function AboutUsComponent() {
  return (
    <React.Fragment>
      <div className="container PrivacyPolicyComponent mt-5 mb-5">
        <div
          style={{
            paddingBottom: "33px",
            paddingLeft: "33px",
            paddingRight: "33px",
          }}
        >
          <h1 style={{ textAlign: "center" }}>About Us</h1>
          <p style={{ textAlign: "center" }}>
            FindSocial is a platform designed to gather public information from
            various platforms.
          </p>
          <p style={{ textAlign: "center" }}>
            With FindSocial you can find any relevant contact and reach out to
            them. The days of manually gathering relevant contacts are over!
            With FindSocial you have a powerful tool to do this all for you.
          </p>
          <p style={{ textAlign: "center" }}>
            Final any contact from platforms such as TikTok, Spotify artists,
            Spotify playlists, SoundCloud, Instagram, YouTube and more.
          </p>
          <p style={{ textAlign: "center" }}>
            In case you are interested or have meow questions, please contact us
            at:
          </p>
          <form>
            <label>Name</label>
            <InputComponent
              classinput={"inputBg"}
              placeholder={"Name"}
              type={"text"}
              required={true}
            />
            <label>Email</label>
            <InputComponent
              classinput={"inputBg"}
              placeholder={"email"}
              type={"email"}
              required={true}
            />
            <label>Ask Questions</label>
            <textarea
              rows={5}
              className="inputBg input-main"
              placeholder="ask questions"
            ></textarea>
            <ButtonComponent text={"submit"} btnclass="btn-background mb-2" />
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}
export default AboutUsComponent;
