import React from "react";
import HorizontalStepper from "../Components/HorizontalStepper";
import { useNavigation } from "react-router-dom";
import { Dialog } from "@mui/material";
export default function PrcingModal({
  setUserData,
  setApiCall,
  apiCall,
  userData,
}) {
  const { goBack } = useNavigation();
  return (
    <Dialog
      open={true}
      onClose={goBack}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: { borderRadius: "15px", width: "400px", textAlign: "center" },
      }}
    >
      <div className="SubscriptionPlan">
        <h1>Select Your Plan</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus
          possimus cumque aliquam laboriosam, <br />
          consequuntur omnis fuga. Sed, non. Laborum, rem officiis!
        </p>
        <HorizontalStepper
          navigate={goBack}
          modal={true}
          userData={userData}
          setApiCall={setApiCall}
          apiCall={apiCall}
          setUserData={setUserData}
        />
      </div>
    </Dialog>
  );
}
