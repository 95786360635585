import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import { Pagination } from "swiper/modules";
import { Modal } from "react-bootstrap";

export default function SocailSlider({ show, handleClose, selectedIndex }) {
  let data = [
    {
      heading: "Instagram",
      src: "./Assets/Images/landing/socail/instragram.png",
      text: "​Quickly discover and access influencer contact information on Instagram, making it simple for businesses and brands to connect with popular Instagram influencers for collaborations, promotions, and partnerships, all in one convenient platform.",
    },
    {
      heading: "SoundCloud",
      src: "./Assets/Images/landing/socail/soundclould.png",
      text: "Streamline the process of finding and accessing artist contact information from Soundcloud, making it very easy and fast to connect with musicians, bands, producers, vocalists and other artists.",
    },
    {
      heading: "Spotify",
      src: "./Assets/Images/landing/socail/shopify.png",
      text: "Easily find artist or playlist contact information from Spotify, enabling musicians, record labels, agencies and playlist curators to connect, collaborate, and expand their networks with just a few clicks, all in one user-friendly platform.",
    },
    {
      heading: "TikTok",
      src: "./Assets/Images/landing/socail/tiktok.png",
      text: "The go-to tool for quickly and easily discovering influencer contact information on TikTok, empowering artists, creators and businesses to connect, collaborate, and grow their TikTok networks with ease.",
    },
    {
      heading: "YouTube",
      src: "./Assets/Images/landing/socail/youtube.png",
      text: "The ultimate tool for effortlessly discovering and accessing channel information and contact details from YouTube, empowering artists, creators and businesses to connect, collaborate, and grow their YouTube networks with ease.",
    },
    {
      heading: "Twitter",
      background: true,
      src: "./Assets/Images/landing/socail/twitter.webp",
      text: "Explore and obtain contact information for influencers, thought leaders, and trendsetters on Twitter. Simplifying the process of connecting with key figures in your industry, fostering collaborations, partnerships, and engagement. All within the user-friendly environment of FindSocial.",
    },
  ];
  useEffect(() => {
    document.documentElement.style.setProperty("--swiper-theme-color", "#000");
    document.documentElement.style.setProperty(
      "--swiper-navigation-size",
      "22px"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-color",
      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bullet-inactive-color",
      "linear-gradient(90deg, #01C38D 12.66%, #7728B5 100%)"
    );
    document.documentElement.style.setProperty(
      "--swiper-pagination-bottom",
      "0px"
    );
    document.documentElement.style.setProperty(
      "--swiper-navigation-top-offset",
      "50%"
    );
    document.documentElement.style.setProperty(
      "--swiper-navigation-sides-offset",
      "0%"
    );
  }, [show]);
  return (
    <Modal show={show} onHide={handleClose} className="modal-Slider">
      <Modal.Body>
        <div className="modal-close-button" onClick={handleClose}>
          <img src="./Assets/Images/landing/socail/back.png" alt="" />
        </div>
        <Swiper
          loop={true}
          pagination={{
            clickable: true,
          }}
          initialSlide={selectedIndex}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="socail-swiper"
        >
          {data?.map((e, i) => (
            <SwiperSlide key={i}>
              <div className="socail-card socail-card-slider">
                <div className="inner-socail-card">
                  {e?.background ? (
                    <div className="twitter-image-logo">
                      <img src={e.src} alt="" />
                    </div>
                  ) : (
                    <img src={e.src} alt="" />
                  )}
                  <h6>{e.heading}</h6>
                  <p className="socail-card-para">{e.text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal.Body>
    </Modal>
  );
}
