import Loader from "./loader";

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InprogressComponent(props) {
  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            maxWidth: "415px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {props?.headerText}
        </DialogTitle>
        <DialogContent>
          <Loader />
        </DialogContent>
        <DialogContentText>{props?.paraText}</DialogContentText>
      </Dialog>
    </div>
  );
}
