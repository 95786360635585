import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import FilterCards from "./FilterCards";
import { useDeepSearch } from "../store/useDeepSearch";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    position: "absolute",
    length: 0,
    maxWidth: "100%",
    right: 0,
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 449,
    backgroundColor: "#FAFAFA",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function FilterMenu({
  isActive,
  setFilterObj,
  filterObj,
  platform,
  disabledButton,
  disableScrapping,
}) {
   const { deepSearch,setDeepSearchFalse  } = useDeepSearch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isToggled, setIsToggled] = React.useState(false);
  return (
    <>
      <button
        type="button"
        className="filter-icon"
        id="search"
        style={disableScrapping ? { opacity: 0.5 } : { opacity: 1 }}
        onClick={!disableScrapping && handleClick}
        disabled={disabledButton}
      >
        {isActive || deepSearch ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-filter text-green-700"
          >
            <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
          </svg>
        ) : (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-filter text-black"
        >
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
        </svg>
        )}
      </button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <FilterCards
          onClose={handleClose}
          setDeepSearchFalse={setDeepSearchFalse}
          setFilterObj={setFilterObj}
          filterObj={filterObj}
          platform={platform}
          disabledButton={disabledButton}
          isToggled={isToggled}
          setIsToggled={setIsToggled}
        />
      </StyledMenu>
    </>
  );
}
