import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box } from "@mui/material";
import BasicTabs from "./BasicTabs";
import StripeComponent from "./stripecomponent";
import { Processing } from "./Processing";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "transparent",
      border: "2px dashed grey",
      margin: "0px 35px",
      borderRadius: "5px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      margin: "0px 35px",
      backgroundColor: "transparent",
      border: "2px dashed grey",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    margin: "0px 35px",
    backgroundColor: "transparent",
    border: "2px dashed grey",
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Pricing plan", "Add Card", "Processing"];
const stripeintent = require("stripe")(
  process.env.REACT_APP_STRIPE_CLIENT_SECRET
);
export default function HorizontalStepper({
  userData,
  setUserData,
  navigate,
  modal,
  updateFromModal,
  openlinkModal,
  subscription,
  stepsNumber,
  packages,
}) {
  const navigation = useNavigate();
  const [activeStep, setActiveStep] = React.useState(
    typeof stepsNumber === "number" ? stepsNumber : 0
  );
  const [skipped, setSkipped] = React.useState(new Set());
  const picingData = {};
  const [name, setName] = React.useState("");
  const [couponCode, setCouponCode] = React.useState("");
  const [cardDetail, setCardDetail] = React.useState({
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  const [expiryMonth, setExpiryMonth] = React.useState("");
  const [expiryDate, setExpiryDate] = React.useState("");
  const [cardNumber, setCardNumber] = React.useState("");
  const [currencyDropdownDisabled, setCurrencyDropdownDisabled] =
    React.useState(false);
  const [selectCurrency, setSelectCurrency] = React.useState("USD");
  useEffect(() => {
    if (subscription?.length) {
      setSelectCurrency(subscription[0]?.currency.toUpperCase());
      setCurrencyDropdownDisabled(true);
    }
  }, [subscription]);
  const handleChange = (id, price, index, text) => {
    handleStripe(id, price, index, text);
  };
  const handleStripe = (id, price, index, text) => {
    if (userData) {
      if (price === 0) {
        navigate(
          `/stripe-auth?select=free&${
            window.location.search ? window.location.search.split("?")[1] : ""
          }`
        );
      } else {
        let obj = {
          customer: userData?.app_metadata?.stripe_customer_id,
          success_url: process.env.REACT_APP_DEPLOY_DOMAIN + "/stripe-auth",
          line_items: [{ price: id, quantity: 1 }],
          mode: "subscription",
          currency: selectCurrency.toLowerCase(),
          metadata: {
            customerId: userData?.app_metadata?.stripe_customer_id,
            productId: id,
          },
          allow_promotion_codes: true,

          payment_method_types: ["card"],
          success_url:
            process.env.REACT_APP_DEPLOY_DOMAIN +
            `/stripe-auth${window.location.search}`,
          cancel_url: `${process.env.REACT_APP_DEPLOY_DOMAIN}/subscription-plan${window.location.search}`,
        };
        stripeintent.checkout.sessions
          .create(obj)
          .then((res) => {
            localStorage.setItem("payment-intent", res?.id);
            window.location.assign(res.url);
          })
          .catch((err) => {
            toast.error(err?.message);
          });
      }
    }
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (picingData?.id) {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        handleStripe(picingData?.id, picingData?.price);
      } else {
        toast.error("Please select a pricing plan");
      }
    } else if (activeStep === 1) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      handleSubmit();
    } else {
    }
  };
  const handleSubmit = async (event) => {
    try {
      if (picingData?.heading === "Free") {
        if (userData?.user_metadata?.freeVersion === "claimed") {
          toast.error("You have claimed free version");
          handleBack();
          return;
        }

        const subscription = await stripeintent.subscriptions.create({
          customer: userData?.app_metadata?.stripe_customer_id,
          items: [{ price: picingData?.id }],
          discounts: [{}],
        });
        let user_metadata = userData?.user_metadata?.subscriptionPlan
          ? {
              subscriptionPlan: "Free",
              remainingCredits:
                Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                userData?.user_metadata?.remainingCredits,
              credits:
                Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                userData?.user_metadata?.credits,
              exp_date: subscription?.current_period_start,
              freeVersion: "claimed",
            }
          : {
              subscriptionPlan: "Free",
              remainingCredits: Number(
                process.env.REACT_APP_STRIPE_CREDITS_FREE
              ),
              credits: Number(process.env.REACT_APP_STRIPE_CREDITS_FREE),
              exp_date: subscription?.current_period_start,
              freeVersion: "claimed",
            };
        var options = {
          method: "post",
          url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
          headers: { "content-type": "application/json" },
          data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
        };
        axios(options)
          .then((res) => {
            var options = {
              method: "PATCH",
              url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
              headers: {
                authorization: `Bearer ${res.data?.access_token}`,
                "content-type": "application/json",
              },
              data: {
                user_metadata: user_metadata,
              },
            };
            axios
              .request(options)
              .then(function (response) {
                setUserData(response.data);

                if (updateFromModal) {
                  openlinkModal();
                } else {
                  navigation("/home");
                }
              })
              .catch(function (error) {});
          })
          .catch(function (error) {});
      } else {
        let coupon = {};
        if (couponCode !== "") {
          coupon = await stripeintent.coupons.retrieve(couponCode);
        }
        const paymentMethod = await stripeintent.paymentMethods.create({
          type: "card",
          card: {
            number: cardDetail?.number,
            exp_month: cardDetail?.exp_month,
            exp_year: cardDetail?.exp_year,
            cvc: cardDetail?.cvc,
          },
        });
        const paymentMethodAttached = await stripeintent.paymentMethods.attach(
          paymentMethod?.id,
          { customer: userData?.app_metadata?.stripe_customer_id }
        );
        const subscription = await stripeintent.subscriptions.create({
          customer: userData?.app_metadata?.stripe_customer_id,
          items: [{ price: picingData?.id }],
          expand: ["latest_invoice.payment_intent"],
          default_payment_method: paymentMethodAttached?.id,
          discounts: [
            coupon?.id
              ? {
                  coupon: coupon?.id,
                }
              : {},
          ],
        });
        let user_metadata = userData?.user_metadata?.subscriptionPlan
          ? {
              subscriptionPlan: picingData?.heading,
              remainingCredits:
                25 === subscription?.plan?.amount / 100
                  ? 1000 + userData?.user_metadata?.remainingCredits
                  : 100 === subscription?.plan?.amount / 100
                  ? 10000 + userData?.user_metadata?.remainingCredits
                  : 200 === subscription?.plan?.amount / 100
                  ? 50000 + userData?.user_metadata?.remainingCredits
                  : null,
              credits:
                25 === subscription?.plan?.amount / 100
                  ? 1000 + userData?.user_metadata?.credits
                  : 100 === subscription?.plan?.amount / 100
                  ? 10000 + userData?.user_metadata?.credits
                  : 200 === subscription?.plan?.amount / 100
                  ? 50000 + userData?.user_metadata?.credits
                  : null,
              exp_date: subscription?.current_period_start,
            }
          : {
              subscriptionPlan: picingData?.heading,
              remainingCredits:
                25 === subscription?.plan?.amount / 100
                  ? 1000
                  : 100 === subscription?.plan?.amount / 100
                  ? 10000
                  : 200 === subscription?.plan?.amount / 100
                  ? 50000
                  : null,
              credits:
                25 === subscription?.plan?.amount / 100
                  ? 1000
                  : 100 === subscription?.plan?.amount / 100
                  ? 10000
                  : 200 === subscription?.plan?.amount / 100
                  ? 50000
                  : null,
              exp_date: subscription?.current_period_start,
            };
        var options = {
          method: "post",
          url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
          headers: { "content-type": "application/json" },
          data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
        };
        axios(options)
          .then((res) => {
            var options = {
              method: "PATCH",
              url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
              headers: {
                authorization: `Bearer ${res.data?.access_token}`,
                "content-type": "application/json",
              },
              data: {
                user_metadata: user_metadata,
              },
            };
            axios
              .request(options)
              .then(function (response) {
                setUserData(response.data);
                if (updateFromModal) {
                  openlinkModal();
                } else {
                  navigation("/home");
                }
              })
              .catch(function (error) {
              });
          })
          .catch(function (error) {
          });
      }
    } catch (error) {
      toast.error(error.message || "Invalid error");
      handleBack();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if (activeStep === 0 && modal) {
      navigate();
    }
  };
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <div className="stepper-and-body">
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className="css-16uiojp-MuiStepper-root"
          connector={<ColorlibConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <div
                  className={
                    index <= activeStep ? "active-label" : "inactive-label"
                  }
                >
                  {label}
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box className="css-16uiojp-MuiStepper-root ">
          {activeStep === 0 && (
            <BasicTabs
              picingData={picingData}
              handleChange={handleChange}
              userData={userData}
              packages={packages}
              currencyDropdownDisabled={currencyDropdownDisabled}
              selectCurrency={selectCurrency}
              setSelectCurrency={setSelectCurrency}
            />
          )}
          {activeStep === 1 && (
            <StripeComponent
              picingData={picingData}
              handleNext={handleNext}
              name={name}
              setName={setName}
              setCardDetail={setCardDetail}
              userData={userData}
              cardDetail={cardDetail}
              setExpiryMonth={setExpiryMonth}
              expiryMonth={expiryMonth}
              setExpiryDate={setExpiryDate}
              expiryDate={expiryDate}
              setCardNumber={setCardNumber}
              cardNumber={cardNumber}
              setCouponCode={setCouponCode}
              couponCode={couponCode}
            />
          )}
          {activeStep === 2 && (
            <Processing
              header={"Processing"}
              text={"Please wait while the page is loading."}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <div className="stepper-btns">
              {activeStep !== 0 && activeStep !== 2 ? (
                <img
                  src="./Assets/Images/backButton.png"
                  onClick={handleBack}
                  className="back-btn"
                  alt="hello"
                  srcset=""
                />
              ) : (
                ""
              )}

              <Box />

              {activeStep === 2 ? (
                <img
                  src="./Assets/Images/backButton.png"
                  onClick={handleBack}
                  className="back-btn"
                  alt="hello"
                  srcset=""
                  style={{ marginBottom: "50px" }}
                />
              ) : null}
            </div>
          </Box>
        </Box>
      </div>
    </Stack>
  );
}
