import React from "react";

export function InstagramFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const containerStyle = { marginTop: 16 };
  const labelStyle = { display: "flex", gap: 27, flexDirection: "column" };
  const inputGroupStyle = { display: "flex", gap: 17 };
  const inputStyle = {
    display: "flex",
    alignItems: "center",
    gap: 10,
    color: "#878787",
    fontSize: "14px",
  };

  const handleInputChange = (key, value) => {
    const numericValue =Number( value)
    if (!isNaN(numericValue) && numericValue >= 0) {
      setFilterObj({
        ...filterObj,
        [key]: numericValue,
      });
    }
  };

  const sections = [
    {
      label: "Number of followers",
      minKey: "min_followers",
      maxKey: "max_followers",
    },
    { label: "Number of Posts", minKey: "min_posts", maxKey: "max_posts" },
  ];

  return (
    <div style={containerStyle}>
      {sections.map((section, index) => (
        <div key={index} style={{ marginTop: index ? 16 : 0 }}>
          <div style={labelStyle}>
            <p className="heading">{section.label}</p>
          </div>
          <div style={inputGroupStyle}>
            {["Min", "Max"].map((type) => {
              const key = section[type === "Min" ? "minKey" : "maxKey"];
              return (
                <div key={type} style={inputStyle}>
                  <label>{type}</label>
                  <input
                    type="number"
                    min={0}
                    disabled={disabledButton}
                    value={filterObj?.[key] || ""}
                    onChange={(e) => handleInputChange(key, e.target.value)}
                    placeholder="0"
                    className="filter-menu-input"
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
export function InstagramShouldBe({ filterObj, setFilterObj }) {
  const buttonConfig = [
    { label: "Private account", key: "is_private" },
    // { label: "Has Clips", key: "has_clips" },
    // { label: "Has bio link", key: "has_bio_link" },
    { label: "Verified account", key: "is_verified" },
    { label: "Professional account", key: "is_professional_account" },
    // { label: "Business account", key: "is_business_account" },
    // { label: "Has Email", key: "has_email" },
  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };
  return (
    <div style={{ display: "flex", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
