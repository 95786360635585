import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderUI from "./HeaderUI";
import ModalUI from "./ModalUI";
import PlatformsComponent from "../Components/platformsComponent";
import { useDeepSearch } from "../store/useDeepSearch";
import { w3cwebsocket } from "websocket";
function HomeUI({
  userData,
  setApiCall,
  setUserData,
  apiCall,
  isLoading,
  isAuthenticated,
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigation = useNavigate();
  const { setDeepSearchFalse } = useDeepSearch();

  useEffect(() => {
    setDeepSearchFalse();
  }, []);
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigation("/");
    } else if (userData?.user_id) {
      if (!userData?.user_metadata?.subscriptionPlan) {
        navigation("/subscription-plan");
      }
    }
  }, [userData, isLoading, isAuthenticated]);


 
  
  
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="home-ui-child-bg">
        <div className="bg-child-platform">
          <h3>Select your platform</h3>
          <p>
            Get contacts fast. Search contacts on any platform including Tiktok,
            Instagram, Spotify, Spotify playlists,
            <br /> SoundCloud, YouTube and Twitter.
          </p>
        </div>
        <div className="socail-icons-box-parent">
          <img src={"./Assets/Images/landing/socail/instragram.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/soundclould.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/shopify.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/tiktok.png"} alt="" />
          <img src={"./Assets/Images/landing/socail/youtube.png"} alt="" />
          <div className="twitter-social-icom-list hidden">
            <img src={"./Assets/Images/landing/socail/twitter.webp"} alt="" />
          </div>
        </div>
      </div>
      <PlatformsComponent navigation={navigation} handleShow={handleShow} />
      <ModalUI
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        navigation={navigation}
      />
    </React.Fragment>
  );
}

export default HomeUI;
