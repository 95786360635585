import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FooterComponent from "../Components/footerComponent";
import ImageComponent from "../Components/imageComponent";
import StripeComponent from "../Components/stripecomponent";
import SubscriptionCards from "../Components/subscriptionCards";
import HeaderUI from "./HeaderUI";

export default function PaymentComponent({
  userData,
  setUserData,
  apiCall,
  setApiCall,
  isLoading,
  isAuthenticated,
}) {
  const navigation = useNavigate();
  var url = new URL(window.location);
  let subscriptionPlan = url.searchParams.get("subscription-Plan");
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      return navigation("/home");
    }
    if (!subscriptionPlan) {
      navigation("/subscription-plan");
    }
  }, [subscriptionPlan, isLoading, isAuthenticated]);
  return (
    <React.Fragment>
      <HeaderUI
        setUserData={setUserData}
        whiteLogo={true}
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
      />
      <div className="LoginComponent mainComponent">
        <div className="section-1" style={{ paddingRight: "50px" }}>
          <div className="header-text">Selected Plan</div>
          <p className="para-text" style={{ marginBottom: "30px" }}>
            Lorem ipsum dolor sit amet consectetur. Leo elit eu nec sit
            volutpat. Consectetur tortor velit placerat aliquet tellus. Id
            volutpat proin tellus dignissim.
          </p>
          {subscriptionPlan === "Starter" ? (
            <SubscriptionCards
              navigation={navigation}
              plansText={"Starter"}
              headertext={"€25"}
              headerpara={"/Billed monthly"}
              SubscriptionText={"Buy Now"}
              subscriptionPlans={[
                "1000 credits per month",
                "Instagram search",
                "Instagram URL imports feature",
                "Spotify artists search",
                "Spotify playlists search",
                "SoundCloud search",
                "TikTok search",
                "Youtube search",
                "Twitter search",
                "Export of results",
              ]}
              confirmPayment={true}
            />
          ) : subscriptionPlan === "Medium" ? (
            <SubscriptionCards
              navigation={navigation}
              plansText={"Medium"}
              headertext={"€100"}
              headerpara={"/Billed monthly"}
              SubscriptionText={"Buy Now"}
              subscriptionPlans={[
                "10000 credits per month",
                "Instagram search",
                "Instagram URL imports feature",
                "Spotify artists search",
                "Spotify playlists search",
                "SoundCloud search",
                "TikTok search",
                "Youtube search",
                "Twitter search",
                "Export of results",
                "Priority support ",
              ]}
              confirmPayment={true}
            />
          ) : subscriptionPlan === "Pro" ? (
            <SubscriptionCards
              navigation={navigation}
              plansText={"Pro"}
              headertext={"€200"}
              headerpara={"/Billed monthly"}
              SubscriptionText={"Buy Now"}
              subscriptionPlans={[
                "50000 credits per month",
                "Instagram search",
                "Instagram URL imports feature",
                "Spotify artists search",
                "Spotify playlists search",
                "SoundCloud search",
                "TikTok search",
                "Youtube search",
                "Twitter search",
                "Export of results",
                "Priority support",
              ]}
              confirmPayment={true}
            />
          ) : subscriptionPlan === "Free" ? (
            <SubscriptionCards
              navigation={navigation}
              confirmPayment={true}
              plansText={"€0"}
              headertext={"€0"}
              headerpara={""}
              SubscriptionText={"Start for free"}
              subscriptionPlans={[
                "Use of our features",
                "Any platform to use",
                "100 credits per month",
              ]}
            />
          ) : null}
        </div>
        <div className="section-2">
          <div className="left-Image">
            <div
              className="left-Image-child"
              onClick={() => navigation("/subscription-plan")}
            >
              <ImageComponent src={"/Assets/Images/Left.png"} />
              Back
            </div>
          </div>
          <StripeComponent
            apiCall={apiCall}
            setUserData={setUserData}
            setApiCall={setApiCall}
            userData={userData}
            url={url}
          />
        </div>
      </div>
      <FooterComponent background={true} />
    </React.Fragment>
  );
}
