import React from "react";
import { Link } from "react-router-dom";
import ImageComponent from "../Components/imageComponent";

export default function BreadCrumb({ page, handleopen, drawer }) {
  const BreadCrumbArray =
    page === "Account overview" ? ["Home", page] : ["Home", page, "Dashboard"];
  const BreadCrumbArrayLength = BreadCrumbArray.length - 1;

  return (
    <React.Fragment>
      <nav className="p-4 margin-right bread-crumb-component">
        <ol className="breadcrumb m-0 align-items-center">
          <ImageComponent
            src="/Assets/Images/Home.png"
            style={{ marginRight: "10px" }}
          />
          {BreadCrumbArray.map((data, index) => (
            <li
              key={index}
              className={
                index === BreadCrumbArrayLength
                  ? "breadcrumb-item active"
                  : "breadcrumb-item"
              }
            >
              {index === 0 ? (
                <Link
                  to="/home"
                  className={
                    index === BreadCrumbArrayLength
                      ? "breadcrumb-link-active link-text-active"
                      : "link-text breadcrumb-link"
                  }
                >
                  {data}
                </Link>
              ) : (
                <Link
                  className={
                    index === BreadCrumbArrayLength
                      ? "breadcrumb-link-active link-text-active"
                      : "link-text breadcrumb-link"
                  }
                >
                  {data}
                </Link>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </React.Fragment>
  );
}

//  BreadCrumbSpotifyPlaylistUI;
