import React, { useState } from "react";
import { PlaylistPreference } from "./platfroms/spotifyPlaylist";

function PlaylistPreferenceAccount({
  platform,
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-filter">
      <div
        className="filter-menu-label"
        onClick={toggleContent}
        style={{ cursor: "pointer" }}
      >
        <div>Playlists preference:</div>
        <div>
          {isOpen ? (
            <svg
              width="10"
              height="2"
              viewBox="0 0 10 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.896 0.0920001V1.832L0.476 1.844V0.104L9.896 0.0920001Z"
                fill="#262626"
              />
            </svg>
          ) : (
            <svg
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.832 4.412V6.128L0.476 6.152V4.424L10.832 4.412ZM6.536 10.448H4.82L4.796 0.0919995H6.524L6.536 10.448Z"
                fill="#262626"
              />
            </svg>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          {platform == "Spotify playlists" && (
            <PlaylistPreference
              disabledButton={disabledButton}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
            />
          )}
        </>
      )}
    </div>
  );
}

export default PlaylistPreferenceAccount;
