import ButtonComponent from "../Components/buttonComponent";
import InputComponent from "../Components/inputComponent";
import FilterMenu from "./FilterMenu";
import CSVModal from "../Components/CSVModal";
import { Tooltip } from "antd";

const FilterComponent = ({
  disableScrapping,
  disableScrappingImport,
  filterText,
  onFilter,
  filterTextclick,
  setFiles,
  data,
  disabledButton,
  isExpire,
  setFilterObj,
  filterObj,
  platform,
  userData,
  importSearch,
  isActive,
}) => (
  <>
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 container-fuild-margin">
        <label
          htmlFor="exampleFormControlInput1"
          className="form-label font-size"
        >
          Search
        </label>
        <div className="input-group justify-content-between">
          <form
            id={"form-Submit-Search"}
            className={
              data.name === "Instagram" &&
              userData?.user_metadata?.subscriptionPlan !== "Free"
                ? "input-search-scrap"
                : "input-search-scrap add-width-New"
            }
            disabled={disableScrapping}
            onSubmit={(e) => {
              e.preventDefault();
              if (!disableScrapping) {
                if (!disabledButton) {
                  e.preventDefault();
                  filterTextclick(
                    importSearch ? filterText.split(",") : filterText,
                    isExpire
                  );
                }
              }
            }}
          >
            <InputComponent
              type="text"
              classinput="input-search"
              id="search-Type"
              value={filterText}
              disabled={
                data.name === "Instagram"
                  ? disableScrapping
                    ? true
                    : disabledButton
                  : disabledButton
              }
              onChange={onFilter}
              name="search"
              placeholder="Search"
            />
            <FilterMenu
              isActive={isActive}
              disableScrapping={
                data.name === "Instagram"
                  ? disableScrapping && disableScrappingImport
                  : disableScrapping
              }
              disabledButton={disabledButton}
              setFilterObj={setFilterObj}
              filterObj={filterObj}
              platform={platform}
            />
          </form>
          <div
            className={
              data.name === "Instagram" &&
              userData?.user_metadata?.subscriptionPlan !== "Free"
                ? `import-csv-scrapper`
                : "import-csv-scrapper extra-width"
            }
          >
            {data.name === "Instagram" &&
            disableScrappingImport &&
            disableScrapping ? (
              <Tooltip
                placement="topLeft"
                title={"This platform is under maintenance"}
                className="btn-main btn-background btn-background-tooltip"
                arrow={true}
              >
                Search
              </Tooltip>
            ) : data.name === "Instagram" && disableScrappingImport ? (
              <ButtonComponent
                disabled={disabledButton}
                onClick={(e) => {
                  if (data.name !== "Twitter") {
                    if (!disabledButton) {
                      e.preventDefault();
                      filterTextclick(
                        importSearch ? filterText.split(",") : filterText,
                        isExpire
                      );
                    }
                  }
                }}
                btnclass="btn-background"
                text={disabledButton? "Loading..." :"Search"}

                style={{
                  height: "50px",
                  whiteSpace: "nowrap",
                  borderRadius: "10px",
                  width: "150px",
                  opacity: disabledButton ? 0.5 : 1,
                }}
              />
            ) : data.name !== "Instagram" && disableScrapping ? (
              <Tooltip
                placement="topLeft"
                title={"This platform is under maintenance"}
                className="btn-main btn-background btn-background-tooltip"
                arrow={true}
              >
                Search
              </Tooltip>
            ) : (
              <ButtonComponent
                disabled={disabledButton}
                onClick={(e) => {
                  if (data.name !== "Twitter") {
                    if (!disabledButton) {
                      e.preventDefault();
                      filterTextclick(
                        importSearch ? filterText.split(",") : filterText,
                        isExpire
                      );
                    }
                  }
                }}
                btnclass="btn-background"
                text={disabledButton? "Loading..." :"Search"}
                style={{
                  height: "50px",
                  whiteSpace: "nowrap",
                  borderRadius: "10px",
                  width: "150px",
                  opacity: disabledButton ? 0.5 : 1,
                }}
              />
            )}
            {data.name === "Instagram" &&
            userData?.user_metadata?.subscriptionPlan !== "Free" ? (
              <>
                <div className="or">or</div>
                <CSVModal
                  disableScrapping={disableScrappingImport}
                  disabledButton={disabledButton}
                  setFiles={setFiles}
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </>
);

export default FilterComponent;
