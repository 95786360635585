import React from "react";

export function ArtistFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const fields = [
    { label: "Number of Followers", key: "followers", type: "number" },
    {
      label: "Monthly Listens",
      key: "listens",
      type: "number",
    },
  ];

  return (
    <div
      style={{
        marginTop: 16,
        alignItems: "center",
        display: "flex",
        gap: 25,
        flexWrap: "wrap",
      }}
    >
      {fields.map((field) => (
        <div key={field.key}>
          <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">{field.label}</p>
          </div>
          <div style={{ display: "flex", gap: 17 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                color: "#878787",
                fontSize: "14px",
              }}
            >
              <label>Min</label>
              <input
                type={field.type}
                disabled={disabledButton}
                value={filterObj?.[field.key] || ""}
                onChange={(ev) => {
                  const numericValue = Number(ev.target.value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    setFilterObj({
                      ...filterObj,
                      [field.key]: numericValue,
                    });
                  }
                }}
                placeholder="0"
                className="filter-menu-input"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function ArtistShouldBe({ filterObj, setFilterObj }) {
  const buttonConfig = [
    { label: "Verified account", key: "verified" },

  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };
  return (
    <div style={{ display: "flex", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
