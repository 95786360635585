import React from "react";

export function TikTokFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  return (
    <div style={{ marginTop: 16 }}>
      <div style={{     marginTop: 16,
        alignItems: "center",
        display: "grid",
        gap: 25,
        gridTemplateColumns: "repeat(2, 1fr)",}}>
          <div>
          <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">Number of followers: </p>
          </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label>Min</label>
          <input
            type="number"
            disabled={disabledButton}
            value={filterObj?.followers || ""}

            onChange={(ev) => {
              const numericValue = Number(ev.target.value);
              if (!isNaN(numericValue) && numericValue >= 0) {
                setFilterObj({
                  ...filterObj,
                  followers: numericValue,
                });
              }
            }}
            placeholder="0"
            className="filter-menu-input"
          />
        </div>
          </div>
        <div>
        <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">Number of followings: </p>
          </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label>Min</label>
          <input
            type="number"
            disabled={disabledButton}
            value={filterObj?.following || ""}
       
            onChange={(ev) => {
              const numericValue = Number(ev.target.value);
              if (!isNaN(numericValue) && numericValue >= 0) {
                setFilterObj({
                  ...filterObj,
                  following: numericValue,
                });
              }
            }}
            placeholder="0"
            className="filter-menu-input"
          />
        </div>
        </div>
        <div>
        <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">Number of likes: </p>
          </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label> Min</label>
          <input
            type="number"
            disabled={disabledButton}
            value={filterObj?.likes || ""}
            onChange={(ev) =>
              setFilterObj({
                ...filterObj,
                likes: ev.target.value,
              })
            }
            placeholder="0"
            className="filter-menu-input"
          />
        </div>
        </div>
        <div>
        <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">Number of posts: </p>
          </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label>Min</label>
          <input
            type="number"
            disabled={disabledButton}
            value={filterObj?.post || ""}
            onChange={(ev) =>
              setFilterObj({
                ...filterObj,
                post: ev.target.value,
              })
            }
            placeholder="0"
            className="filter-menu-input"
          />
        </div>
        </div>
      </div>
    </div>
  );
}

export function TikTokShouldBe({ filterObj, setFilterObj }) {
  const buttonConfig = [
    { label: "Verified account", key: "verified" },
    { label: "Email", key: "email" },
  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };
  return (
    <div style={{ display: "flex", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
