export default function Notification({
  message,
  isOpen,
  setIsOpenBot,
  isOpenBot,
}) {
  return (
    <div isOpen={isOpen} className="relative w-[400px]">
      <div className="absolute left-1/2 -top-[10px] -translate-x-1/2 w-6 h-6 rotate-45 z-10 bg-gradient-to-r bg-white from-[rgba(1,195,141,0.1)] to-[rgba(119,40,181,0.1)]  shadow-[0px_2px_7px_0px_rgba(0,0,0,0.17)]" />

      <div className="relative w-full z-20 h-[120px] pb-4 pt-1.5 px-3  rounded-lg bg-gradient-to-r bg-white from-[rgba(1,195,141,0.1)] to-[rgba(119,40,181,0.1)]  shadow-[0px_2px_7px_0px_rgba(0,0,0,0.17)]">
        <div className="space-y-2">
          <p className="text-[#262626] text-base font-normal mb-2 ">{message}</p>
          <div className="flex justify-between items-center">
            <button
              onClick={!isOpenBot && setIsOpenBot}
              className="  font-medium breadcrumb-link-active link-text-active underline "
              style={{ textDecorationColor: "#01C38D" }}
            >
              Check Queue
            </button>
        
          </div>
        </div>
      </div>
    </div>
  );
}
