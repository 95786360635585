import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChangePasswordModal from "../Components/changePasswordModal";
import DrawerDialog from "../Components/drawer";
import CurrentPlan from "../Pages/currentPlan";
import ProfileComponent from "../Pages/profileComponent";
import BreadCrumb from "./BreadCrumb";
import { webAuth } from "../Data/Auth/webAuth";
import HeaderUI from "./HeaderUI";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ForgotPasswordModal from "../Components/forgotPasswordModal";
export default function AccountOverview({
  page,
  userData,
  apiCall,
  setApiCall,
  setUserData,
  day,
  hours,
  mint,
  seconds,
  endDate,
  subscription,
  setExpireSubscription,
  setSeconds,
  setMint,
  setHours,
  intervalTime,
  setDay,
  apiToken,
  packages,
  ...props
}) {
  const stripe = require("stripe")(process.env.REACT_APP_STRIPE_CLIENT_SECRET);
  const [isShow, setIsShow] = useState(false);
  const [changeModalIsShow, setChangeModalIsShow] = useState(false);
  const [Password, setPassword] = useState("");
  const [cancelLoader, setCancelLoader] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [forgotPasswordLoader, setForgotPasswordLoader] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const { logout } = useAuth0();

  const handleClose = () => setIsShow(false);
  const handleForgotPasswordClose = () => setForgotPasswordModal(false);
  const handleopen = () => setIsShow(true);
  const navigation = useNavigate();
  const handleChange = () => {
    setChangeModalIsShow(false);
  };
  useEffect(() => {
    if (userData?.user_id) {
      if (!userData?.user_metadata?.subscriptionPlan) {
        navigation("/subscription-plan");
      }
    }
  }, [window.location.pathname, userData]);

  const changePasswordFn = () => {
    setForgotPasswordModal(true);
  };
  const changePasswordSendMail = () => {
    setForgotPasswordLoader(true);
    webAuth.changePassword(
      {
        email: userData?.email,
        // realm: `Username-Password-Authentication`,
        connection: `Username-Password-Authentication`,
        redirectUri: `${props?.url}/account-overview`,
      },
      function (err, resp) {
        if (err) {
          toast.error(err.message);
          handleForgotPasswordClose();
          setForgotPasswordLoader(false);
        } else {
          toast.success(resp);
          handleForgotPasswordClose();
          setForgotPasswordLoader(false);
        }
      }
    );
  };
  const onSubscribtionEnd = () => {
    if (subscription.length) {
      let data = subscription[0];
      setCancelLoader(true);

      stripe.subscriptions
        .del(data?.id)
        .then((customer) => {
          let user_metadata = userData?.user_metadata?.freeVersion
            ? { freeVersion: "claimed" }
            : {};

          var options = {
            method: "PATCH",
            url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
            headers: {
              authorization: `Bearer ${userData?.user_metadata?.token}`,
              "content-type": "application/json",
            },
            data: {
              user_metadata: {},
            },
          };
          axios
            .request(options)
            .then(function (response) {
              if (user_metadata) {
                var options = {
                  method: "PATCH",
                  url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
                  headers: {
                    authorization: `Bearer ${apiToken}`,
                    "content-type": "application/json",
                  },
                  data: {
                    user_metadata: {
                      ...user_metadata,
                      token: apiToken,
                    },
                  },
                };
                axios
                  .request(options)
                  .then(function (response2) {
                    setSeconds(0);
                    setMint(0);
                    setHours(0);
                    setDay(0);

                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    });
                    setUserData({});
                    navigation("/");
                    toast.success("Logged out Successfully");
                  })
                  .catch(function (error) {
                  });
              } else {
                setUserData(response.data);
                setCancelLoader(false);
                setSeconds(0);
                setMint(0);
                setHours(0);
                setDay(0);
                navigation("/subscription-plan");
              }
            })
            .catch(function (error) {
              setCancelLoader(false);
            });
        })
        .catch((error) => {
          setCancelLoader(false);
        });
    }
  };
  useEffect(() => {
    if (!userData?.user_id && !userData?.app_metadata?.stripe_customer_id) {
      navigation("/");
    }
  }, [userData]);
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div>
        <BreadCrumb
          drawer={true}
          handleopen={handleopen}
          page={"Account overview"}
        />
      </div>
      <div className="account-Overview">
        <div className="inner-Account">
          <div className="Profile-res-none">
            <ProfileComponent
              navigation={navigation}
              userData={userData}
              apiCall={apiCall}
              changePasswordFn={changePasswordFn}
              setApiCall={setApiCall}
            />
          </div>
          <CurrentPlan
            userData={userData}
            navigation={navigation}
            setUserData={setUserData}
            intervalTime={intervalTime}
            day={day}
            hours={hours}
            mint={mint}
            subscription={subscription}
            packages={packages}
            setApiCall={setApiCall}
            apiCall={apiCall}
            seconds={seconds}
            endDate={endDate}
            onSubscribtionEnd={onSubscribtionEnd}
            cancelLoader={cancelLoader}
          />
        </div>
      </div>
      <ChangePasswordModal
        changePasswordFn={changePasswordFn}
        isShow={changeModalIsShow}
        handleChange={handleChange}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        Password={Password}
        confirmPassword={confirmPassword}
      />
      <DrawerDialog
        Component={ProfileComponent}
        navigation={navigation}
        userData={userData}
        apiCall={apiCall}
        setApiCall={setApiCall}
        isShow={isShow}
        handleClose={handleClose}
        setChangeModalIsShow={changePasswordFn}
      />
      <ForgotPasswordModal
        forgotPasswordLoader={forgotPasswordLoader}
        open={forgotPasswordModal}
        handleClose={handleForgotPasswordClose}
        changePasswordSendMail={changePasswordSendMail}
      />
    </React.Fragment>
  );
}
