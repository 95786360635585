import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeaderUI from "./HeaderUI";
import HorizontalStepper from "../Components/HorizontalStepper";
export default function SubscriptionPlan({
  userData,
  setApiCall,
  apiCall,
  setUserData,
  subscription,
  packages,
  isAuthenticated,
  isLoading,
}) {
  const navigation = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("payment-intent")) {
      localStorage.removeItem("payment-intent");
    }
  }, []);
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      navigation("/home");
    }
  }, [isAuthenticated, isLoading]);

  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        apiCall={apiCall}
        setUserData={setUserData}
      />
      <div className="SubscriptionPlan">
        <div className="text-innerText text-center">
          <h1>Select Your Plan</h1>
          <p>
            Welcome to our platform! We offer a range of plans tailored to meet
            your specific needs. Whether you're an individual looking for basic
            <br />
            features or a business in search of comprehensive solutions, we have
            the right plan for you. Choose from the options below to get
            started.
          </p>
        </div>
        <HorizontalStepper
          packages={packages}
          userData={userData}
          navigate={navigation}
          setApiCall={setApiCall}
          apiCall={apiCall}
          setUserData={setUserData}
          subscription={subscription}
        />
      </div>
    </React.Fragment>
  );
}
