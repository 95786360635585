import { Tag, Tooltip } from "antd";
import UpgradeModal from "../../Components/UpgradeModal";
import { Link } from "react-router-dom";
const CustomTitle = ({ row }) => (
  <Tooltip placement="topLeft" title={row} arrow={true}>
    <div className="platform-text-show">{row}</div>
  </Tooltip>
);
const SoundCloud = (
  userData,
  setUserData,
  disabledButton,
  upgradeModal,
  setupgradeModal,
  loader,
  setLoader,
  loaderMsgId,
  setLoaderMsgId,
  handleRowClicked
) => {
  let obj = {
    name: "SoundCloud",
    url: "sound-cloud-dashboard",
    previousDataUrl: (user) => `/soundcloud-results/?user=${user}`,
    websocketUrl: `${process.env.REACT_APP_WEBSOCKET}/soundcloud-socket`,
    websocketTestUrl: `${process.env.REACT_APP_API_URL}/soundcloud-test-socket`,
    columns: [
      {
        name: "URL",
        selector: (row) => row?.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row?.url}`} arrow={true}>
            <Link
              to={row?.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row?.url}
            </Link>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "",
        selector: (row) => row.deep_search,
        sortable: disabledButton ? true : false,
        cell: (row) =>
          row?.deep_search ? (
            <Tag
              color={"purple"}
              style={{ cursor: "pointer" }}
              onClick={() => handleRowClicked(row)}
            >
              Deep Search
            </Tag>
          ) : null,
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        cell: (row) => <CustomTitle row={row?.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => row?.followers,
        cell: (row) => <CustomTitle row={row?.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row?.email,
        cell: (row) => <CustomTitle row={row?.email} />,
        // cell: (row) => (
        //   <Tooltip placement="topLeft" title={row?.email} arrow={true}>
        //     <div className="platform-text-show">
        //       {row?.email?.length &&
        //         row?.email?.map((url, index) => (
        //           <span>
        //             {url}
        //             {row?.email?.length - 1 === index ? "" : ","}{" "}
        //           </span>
        //         ))}
        //     </div>
        //   </Tooltip>
        // ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Other emails",
        selector: (row) => row?.other_emails,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row?.other_emails?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row?.other_emails?.length
                ? row?.other_emails?.map((url, index) => (
                    <Link
                      to={`mailto:${url}`}
                      className="platforms-Url platform-text-show"
                      target="_blank"
                    >
                      {url} {row?.other_emails?.length - 1 === index ? "" : ","}{" "}
                    </Link>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "other_emails",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Send message",
        selector: (row) => (
          <UpgradeModal
            disabledButton={disabledButton}
            name={"Sound Cloud"}
            routesname={"sound-cloud"}
            src="/Assets/Images/components/Sendmsg.png"
            socailIcon={"soundclooud"}
            premium={
              userData?.user_metadata?.subscriptionPlan === "Pro" ? true : false
            }
            setLoaderMsgId={setLoaderMsgId}
            loaderMsgId={loaderMsgId}
            cookieName={"soundCloud_oauth_token"}
            userData={userData}
            uid={row?.c_id}
            setUserData={setUserData}
            chatAPItUrl={`${process.env.REACT_APP_API_URL}/soundcloud-send-message`}
            upgradeModal={upgradeModal}
            setupgradeModal={setupgradeModal}
            loader={loader}
            setLoader={setLoader}
          />
        ),
        value: "Send Message",
        style: {
          width: "50px", // override the row height
        },
        sortable: false,
      },
    ],
    columnsDeepSearch: [
      {
        name: "URL",
        selector: (row) => row?.url,
        cell: (row) => (
          <Tooltip placement="topLeft" title={`${row?.url}`} arrow={true}>
            <Link
              to={row?.url}
              className="platforms-Url platform-text-show"
              target="_blank"
            >
              {row?.url}
            </Link>
          </Tooltip>
        ),
        value: "url",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Name",
        selector: (row) => row?.name,
        cell: (row) => <CustomTitle row={row?.name} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Followers",
        selector: (row) => row?.followers,
        cell: (row) => <CustomTitle row={row?.followers} />,
        value: "followers",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Email",
        selector: (row) => row?.email,
        cell: (row) => <CustomTitle row={row?.email} />,
        // cell: (row) => (
        //   <Tooltip placement="topLeft" title={row?.email} arrow={true}>
        //     <div className="platform-text-show">
        //       {row?.email?.length &&
        //         row?.email?.map((url, index) => (
        //           <span>
        //             {url}
        //             {row?.email?.length - 1 === index ? "" : ","}{" "}
        //           </span>
        //         ))}
        //     </div>
        //   </Tooltip>
        // ),
        value: "email",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Other emails",
        selector: (row) => row?.other_emails,
        cell: (row) => (
          <Tooltip
            placement="topLeft"
            title={row?.other_emails?.join(" , ")}
            arrow={true}
          >
            <div className="platform-text-show">
              {row?.other_emails?.length
                ? row?.other_emails?.map((url, index) => (
                    <Link
                      href={`mailto:${url}`}
                      className="platforms-Url platform-text-show"
                      target="_blank"
                    >
                      {url} {row?.other_emails?.length - 1 === index ? "" : ","}{" "}
                    </Link>
                  ))
                : null}
            </div>
          </Tooltip>
        ),
        value: "other_emails",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Name",
        selector: (row) => row?.deep_search?.instagram?.username,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.username} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram URL",
        selector: (row) => row?.deep_search?.instagram?.url,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.url} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Followers",
        selector: (row) => row?.deep_search?.instagram?.followers,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.followers} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Following",
        selector: (row) => row?.deep_search?.instagram?.following,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.instagram?.following} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Instagram Posts",
        selector: (row) => row?.deep_search?.instagram?.posts,
        cell: (row) => <CustomTitle row={row?.deep_search?.instagram?.posts} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok Name",
        selector: (row) => row?.deep_search?.tiktok?.username,
        cell: (row) => <CustomTitle row={row?.deep_search?.tiktok?.username} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok URL",
        selector: (row) => row?.deep_search?.tiktok?.url,
        cell: (row) => <CustomTitle row={row?.deep_search?.tiktok?.url} />,
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "TikTok Followers",
        selector: (row) => row?.deep_search?.tiktok?.followers,
        cell: (row) => (
          <CustomTitle row={row?.deep_search?.tiktok?.followers} />
        ),
        value: "name",
        sortable: disabledButton ? false : true,
        style: {
          width: "240px", // override the row height
        },
      },
      {
        name: "Send message",
        selector: (row) => (
          <UpgradeModal
            disabledButton={disabledButton}
            name={"Sound Cloud"}
            routesname={"sound-cloud"}
            src="/Assets/Images/components/Sendmsg.png"
            socailIcon={"soundclooud"}
            premium={
              userData?.user_metadata?.subscriptionPlan === "Pro" ? true : false
            }
            setLoaderMsgId={setLoaderMsgId}
            loaderMsgId={loaderMsgId}
            cookieName={"soundCloud_oauth_token"}
            userData={userData}
            uid={row?.c_id}
            setUserData={setUserData}
            chatAPItUrl={`${process.env.REACT_APP_API_URL}/soundcloud-send-message`}
            upgradeModal={upgradeModal}
            setupgradeModal={setupgradeModal}
            loader={loader}
            setLoader={setLoader}
          />
        ),
        value: "Send Message",
        style: {
          width: "50px", // override the row height
        },
        sortable: false,
      },
    ],
  };
  return obj;
};
export { SoundCloud };
