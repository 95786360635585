import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../Components/loginFormx";
import { useState } from "react";
import { webAuth } from "../Data/Auth/webAuth";
import { toast } from "react-toastify";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AuthSwiper from "./authSwiper";

function SignUpComponent(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [object, setObject] = useState({
    username: "",
    email: "",
    password: "",
  });
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate("/home");
    }
  }, [localStorage.getItem("accessToken")]);
  const webAuthLogin = async () => {
    webAuth.signup(
      {
        connection: `Username-Password-Authentication`,
        username: object?.username,
        email: object?.email,
        password: object?.password,
      },
      async (err, result) => {
        if (err) {
          setLoader(false);
          return toast.error(
            err?.description || err?.policy || err?.policy || "Invalid error"
          );
        }
        if (result) {
          webAuth.signup(
            {
              realm: `Username-Password-Authentication`,
              responseType: `token`,
              responseMode: `fragment`,
              redirectUri: `${props?.url}/authentication`,
              email: object?.email,
              password: object?.password,
            },
            function (err, authResult) {
              if (err) {
                setLoader(false);
                return toast.error(err?.error_description || "Invalid error");
              }
              if (authResult) {
                if (authResult && authResult.accessToken) {
                  webAuth.client.userInfo(
                    authResult.accessToken,
                    (error, profile) => {
                      if (error) {
                      } else {
                      }
                    }
                  );
                }
              }
            }
          );
        }
      }
    );
  };

  const handleChange = (ev) => {
    ev.preventDefault();
    if (!loader) {
      setLoader(true);
      webAuthLogin();
    }
  };
  const facebookLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "facebook",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
        }
      }
    );
  };
  const googleLogin = () => {
    setLoader(true);
    webAuth.authorize(
      {
        connection: "google-oauth2",
        responseType: `token`,
        responseMode: `fragment`,
        redirectUri: `${props?.url}/authentication`,
      },
      function (err, result) {
        if (err) {
          return toast.error(err?.error_description || "Invalid error");
        }
        if (result) {
        }
      }
    );
  };
  return (
    <React.Fragment>
      <div className="LoginComponent mainComponent">
        <div className="section-2">
          <LoginForm
            text={"Register"}
            loader={loader}
            setLoader={setLoader}
            handleChange={handleChange}
            object={object}
            setObject={setObject}
            facebookLogin={facebookLogin}
            googleLogin={googleLogin}
          />
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignUpComponent;
