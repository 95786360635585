import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonComponent from "./buttonComponent";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNotification } from "../store/useNotification";

export default function SendMessageModal({
  sendMessageModal,
  changeSendMessageModal,
  cookieName,
  cookieName2,
  getCookie,
  socailIcon,
  chatAPItUrl,
  uid,
  userData,
  loader,
  setLoader,
  setLoaderMsgId,
}) {
  const [messageData, setMessageData] = React.useState("");
  const [messageSocket, setMessageSocket] = React.useState("");
  const [client, setClient] = React.useState(null);
  const { isOpenBot, setIsOpenBot, setIsNotification, setMessage } =
    useNotification();
  const onUploadProgress = (progressEvent) => {};
  
  function Message() {

    const client = w3cwebsocket(
      `${process.env.REACT_APP_WEBSOCKET}/message-queue`
    );
    setClient(client);
    client.onopen = () => {
      client.send(
        JSON.stringify({
          user_id: userData.user_id,
        })
      );
    };

    client.onmessage = (message) => {
      console.log("message", message);
      const dataFromServer = JSON.parse(message.data);
      setMessageSocket(
        dataFromServer?.tasks?.length > 0 ? dataFromServer?.tasks : []
      );
    };
    client.onclose = (e) => {
      client?.close();
    };

    client.onerror = () => {
      client?.close();
    };
  }
  let timeoutId;

  function sendMessage() {
    setLoader(true);
    let cookieAuth = getCookie(cookieName);
    var cookieAuth2 = getCookie(cookieName2);
    setLoaderMsgId(uid);
    let data =
      socailIcon === "soundclooud"
        ? {
            username: uid,
            sessionid: cookieAuth2,
            message: messageData,
            user_id: userData?.user_id,
          }
        : socailIcon === "instragram"
        ? {
            username: uid,
            sessionid: cookieAuth2,
            message: messageData,
            user_id: userData?.user_id,
          }
        : {};
    changeSendMessageModal();

    var options = {
      method: "post",
      url: chatAPItUrl,
      onDownloadProgress: onUploadProgress,
      data: data,
    };
    axios(options)
      .then((res) => {
       
        setMessage("Your message has been sent successfully");

        setIsNotification(true);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        if (isOpenBot) {
          setIsOpenBot();
        }

        timeoutId = setTimeout(() => {
          setIsNotification(false);
        }, 3000);
        setMessageData("");
        setLoaderMsgId("");
        setTimeout(() => {
          setLoader(false);
        }, 500);
        Message()
      })
      .catch((error) => {
        toast.error(error?.response?.data?.detail);
        setLoaderMsgId("");
        setLoader(false);
      });
  }
  const handleClose = () => {};

  return (
    <div>
      <Dialog
        open={sendMessageModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="cross-button" onClick={changeSendMessageModal}>
          <CloseIcon />
        </div>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ textAlign: "center", fontSize: "30px", fontWeight: "600" }}
        >
          {/* {loader ? "Sending Message..." : 
          "Send Message"} */}
          Send Message
        </DialogTitle>
        <DialogContent>
          {/* {loader ? (
            <div className="progress-bar-message-loader">
              <ProgressBar />
            </div>
          ) : ( */}
          {/* // )} */}
          <textarea
            name=""
            onChange={(ev) => setMessageData(ev.target.value)}
            placeholder="Send Message here..."
            id=""
            value={messageData}
            className="input-Stripe"
            cols="60"
            rows="10"
          ></textarea>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            style={{
              height: "38px",
              width: "100%",
              whiteSpace: "nowrap",
              borderRadius: "10px",
              margin: "10px",
            }}
            text={loader ? <Spinner /> : "Send"}
            onClick={sendMessage}
            btnclass="btn-background"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}
