import React from "react";

export function PlaylistFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  const fields = [
    {
      label: "Number of likes",
      key: "likes",
      type: "number",
    },
    { label: "Number of tracks", key: "tracks", type: "number" },
  ];

  return (
    <div
      style={{
        marginTop: 16,
        alignItems: "center",
        display: "grid",
        gap: 25,
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      {fields.map((field) => (
        <div key={field.key}>
          <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
            <p className="heading">{field.label}</p>
          </div>
          <div style={{ display: "flex", gap: 17 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                color: "#878787",
                fontSize: "14px",
              }}
            >
              <label>Min</label>
              <input
                type={field.type}
                disabled={disabledButton}
                value={filterObj?.[field.key] || ""}
                onChange={(ev) => {
                  const numericValue = Number(ev.target.value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    setFilterObj({
                      ...filterObj,
                      [field.key]: numericValue,
                    });
                  }
                }}
                placeholder="0"
                className="filter-menu-input"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export function PlaylistShouldBe({ filterObj, setFilterObj, disabledButton }) {


  const buttonConfig = [
    { label: "Collaborative", key: "collaborative" },
    { label: "Public playlist", key: "public" },
    { label: "Private playlist", key: "private" },
  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };


  return (
    <div style={{ display: "flex", width:"100%", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
 
    </div>
  );

 
}

export function PlaylistPreference({ filterObj, setFilterObj, disabledButton }) {
  const genreOptions = [
    "Pop",
    "Rock",
    "Hip-Hop",
    "Jazz",
    "Classical",
    "Electronic",
    "Reggae",
    "Country",
    "Metal",
    "Blues",
    "Alternative",
    "Folk",
    "R&B",
    "Latin",
    "Indie",
  ];

  return (
    <div style={{ display: "flex", width:"100%", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
   
      <div style={{width:"100%"}}>
        <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
          <p className="heading">Genre</p>
        </div>
        <div>
          <select
            disabled={disabledButton}
            value={filterObj?.genre || ""}
            onChange={(ev) => {
              const selectedGenres = Array.from(
                ev.target.selectedOptions,
                (option) => option.value
              );
              setFilterObj({ ...filterObj, genre: selectedGenres });
            }}
            className="filter-menu-input"
            style={{
              width: "100%",
              padding: "5px",
              height: "35px",
              borderRadius: "5px",
              outline: "none",
              border: "1px solid #ccc",
              cursor: disabledButton ? "not-allowed" : "pointer",
            }}
          >
            <option value={""}>Select genre</option>
            {genreOptions.map((genre) => (
              <option key={genre} value={genre}>
                {genre}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
