import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import HomeUI from "./UI/HomeUI";
import Dashboard from "./UI/Dashboard";
import { Tiktok } from "./Data/Models/Tiktok";
import { SpotifyPlaylists } from "./Data/Models/SpotifyPlaylists";
import { SpotifyArtists } from "./Data/Models/SpotifyArtists";
import { SoundCloud } from "./Data/Models/SoundCloud";
import SpotifyInstagramUrls from "./Data/Models/SpotifyInstagramUrls";
import { Instagram } from "./Data/Models/Instagram";
import { Youtube } from "./Data/Models/Youtube";
import LoginComponent from "./UI/Login";
import PaymentComponent from "./UI/payment";
import SubscriptionPlan from "./UI/SubscriptionPlan";
import AccountOverview from "./UI/accountOverview";
import SignUpComponent from "./UI/SignUp";
import AuthenticateComponent from "./UI/authenticateComponent";
import axios from "axios";
import PrivacyPolicyComponent from "./UI/privacyPolicyComponent";
import TermsAndConditions from "./UI/termsAndConditions";
import AboutUsComponent from "./UI/aboutUsComponent";
import StripePaymentauth from "./UI/stripePaymentauth";
import UpdateModal from "./Pages/updateModal";
import VerifyAccount from "./UI/verifyAccount";
import ForgetComponent from "./UI/forgetPassword";
import FeaturesComponent from "./UI/FeaturesComponent";
import PricingComponent from "./UI/PricingComponent";
import LandingPage from "./Pages/landingPage";
import "swiper/css";
import PrcingModal from "./UI/PricingModal";
import LinkIdModal from "./Components/LinkIdModal";
import { Twitter } from "./Data/Models/Twitter";
import { useAuth0 } from "@auth0/auth0-react";
import HeaderUI from "./UI/HeaderUI";
import FooterSection from "./Components/landingPage/footersection";
import { useDeepSearch } from "./store/useDeepSearch";
const stripe = require("stripe")(process.env.REACT_APP_STRIPE_CLIENT_SECRET);
function App() {
  const [ userData, setUserData] = useState({});
  const [loader, setLoader] = React.useState(false);
  const [loaderMsgId, setLoaderMsgId] = React.useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [apiCall, setApiCall] = useState(false);
  const [apiCall2, setApiCall2] = useState(false);
  const [subscription, setSubscription] = useState([]);
  const [apiToken, setApiToken] = useState("");
  const [expireSubscription, setExpireSubscription] = useState(false);
  const [isExpire, setIsExpire] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [mint, setMint] = useState(0);
  const [hours, setHours] = useState();
  const [day, setDay] = useState(0);
  const [intervalTime, setIntervalTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [packages, setPackages] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const url = process.env.REACT_APP_DEPLOY_DOMAIN;
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [upgradeModal, setupgradeModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    stripe.prices
      .list({
        product: process.env.REACT_APP_STRIPE_PRODUCT_ID,
        active: true,
        limit: 10,
        expand: ["data.currency_options"],
      })
      .then((res) => {
        setPackages(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (
      userData?.user_metadata?.subscriptionPlan &&
      userData?.app_metadata?.stripe_customer_id
    ) {
      if (userData?.user_metadata?.subscriptionPlan !== "Free") {
        stripe.subscriptions
          .list({
            customer: userData?.app_metadata?.stripe_customer_id,
          })
          .then((res) => {
            setIntervalTime(res?.data[0]?.plan?.interval);
            if (res?.data?.length > 1) {
              res?.data.forEach((e, i) => {
                if (i !== 0) {
                  stripe.subscriptions
                    .del(e?.id)
                    .then(() => {
                      stripe.subscriptions
                        .list({
                          customer: userData?.app_metadata?.stripe_customer_id,
                        })
                        .then((newRes) => {
                          setSubscription(newRes?.data);
                        })
                        .catch((err) => console.log(err));
                    })
                    .catch((err) => console.log(err));
                }
              });
            } else {
              setSubscription(res?.data);
            }
          })
          .catch((err) => {
            setExpireSubscription(true);
            setIsExpire(true);
          });
      } else {
        setExpireSubscription(false);
        setIsExpire(false);
      }
    }
  }, [userData]);
  useEffect(() => {
    if (
      !subscription?.length &&
      apiCall2 &&
      !userData?.user_metadata?.subscriptionPlan
    ) {
      setApiCall2(false);
      return;
    } else if (
      userData?.user_metadata?.exp_date >= subscription[0]?.current_period_start
    ) {
      let date = subscription[0]?.current_period_end * 1000;

      if (date <= new Date().getTime() * 1000) {
        setIsExpire(false);
        setEndDate(date);
        let refreshDelayMs = 1000;
        function dhm() {
          let endDate = new Date(date).getTime();
          let nowDate = new Date().getTime();
          let t = endDate - nowDate;
          let cd = 24 * 60 * 60 * 1000;
          let ch = 60 * 60 * 1000;
          let cm = 60 * 1000;
          let cs = 1000;
          let d = Math.floor(t / cd);
          let h = Math.floor((t - d * cd) / ch);
          let m = Math.round((t - d * cd - h * ch) / cm);
          let s = Math.round((t - d * cd - h * ch - m * cm) / cs);

          if (Number(d) < 0) {
            setDay(0);
          } else {
            if (Number(d) >= 0) setDay(Number(d));
          }
          if (Number(h) < 0) {
            setHours(0);
          } else {
            if (Number(d) >= 0) setHours(Number(h));
          }
          if (Number(m) < 0) {
            setMint(0);
          } else {
            if (Number(d) >= 0) setMint(Number(m));
          }
          if (Number(s) < 0) {
            setSeconds(0);
          } else {
            if (Number(d) >= 0) setSeconds(Number(s));
          }

          setTimeout(dhm, refreshDelayMs);
        }
        dhm();
      } else {
        setIsExpire(true);
        setExpireSubscription(true);
      }
    } else if (
      userData?.user_metadata?.exp_date <= subscription[0]?.current_period_start
    ) {
      let user_metadata =
        subscription[0]?.plan?.interval === "year"
          ? {
              subscriptionPlan:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_Yearly) ===
                subscription[0]?.plan?.amount / 100
                  ? "Starter"
                  : Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? "Medium"
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? "Pro"
                  : null,
              remainingCredits:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_Yearly) ===
                subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                    ) + userData?.user_metadata?.remainingCredits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly) +
                    userData?.user_metadata?.remainingCredits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_PRO_Yearly) +
                    userData?.user_metadata?.remainingCredits
                  : null,
              credits:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_Yearly) ===
                subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                    ) + userData?.user_metadata?.credits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_MEDIUM_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly) +
                    userData?.user_metadata?.credits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_Yearly) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_PRO_Yearly) +
                    userData?.user_metadata?.credits
                  : null,
              exp_date: subscription[0]?.current_period_start,
            }
          : {
              subscriptionPlan:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_MONTHLY) ===
                subscription[0]?.plan?.amount / 100
                  ? "Starter"
                  : Number(
                      process.env.REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                    ) ===
                    subscription[0]?.plan?.amount / 100
                  ? "Medium"
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_MONTHLY) ===
                    subscription[0]?.plan?.amount / 100
                  ? "Pro"
                  : null,
              remainingCredits:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_MONTHLY) ===
                subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                    ) + userData?.user_metadata?.remainingCredits
                  : Number(
                      process.env.REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                    ) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                    ) + userData?.user_metadata?.remainingCredits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_MONTHLY) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_PRO_MONTHLY) +
                    userData?.user_metadata?.remainingCredits
                  : null,
              credits:
                Number(process.env.REACT_APP_STRIPE_PRICE_STARTER_MONTHLY) ===
                subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                    ) + userData?.user_metadata?.credits
                  : Number(
                      process.env.REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                    ) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(
                      process.env.REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                    ) + userData?.user_metadata?.credits
                  : Number(process.env.REACT_APP_STRIPE_PRICE_PRO_MONTHLY) ===
                    subscription[0]?.plan?.amount / 100
                  ? Number(process.env.REACT_APP_STRIPE_CREDITS_PRO_MONTHLY) +
                    userData?.user_metadata?.credits
                  : null,
              exp_date: subscription[0]?.current_period_start,
            };
      var options = {
        method: "PATCH",
        url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
        headers: {
          authorization: `Bearer ${apiToken}`,
          "content-type": "application/json",
        },
        data: {
          user_metadata: {
            ...user_metadata,
            token: apiToken,
          },
        },
      };
      axios
        .request(options)
        .then(function (response) {
          setUserData(response.data);
          setIsExpire(false);
        })
        .catch(function (error) {});
    }
  }, [subscription, day, hours, mint, seconds, apiCall2, endDate]);

  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      const decodedUser = user?.sub;
      var options = {
        method: "post",
        url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
        headers: { "content-type": "application/json" },
        data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
      };
      axios(options)
        .then((res) => {
          setApiToken(res?.data?.access_token);
          var options = {
            method: "GET",
            url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${decodedUser}`,
            headers: { authorization: `Bearer ${res?.data?.access_token}` },
          };
          axios
            .request(options)
            .then(function (response) {
              setUserData(response.data);
              setUserInfo(response.data);
            })
            .catch(function (error) {});
        })
        .catch((err) => {});
    } else {
      setUserData({});
    }
  }, [apiCall, isAuthenticated, user]);

  useEffect(() => {
    if (!isExpire) {
      setExpireSubscription(false);
    } else if (
      window.location.pathname === "/subscription-plan" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/authentication" ||
      window.location.pathname === "/authConnect" ||
      window.location.pathname === "/stripe-payment" ||
      window.location.pathname === "/pricing" ||
      window.location.pathname === "/subscription-modal" ||
      window.location.pathname === "/forget-password" ||
      window.location.pathname === "/terms-and-conditions" ||
      window.location.pathname === "/about-us" ||
      window.location.pathname === "/features" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/verify-account" ||
      window.location.pathname === "/stripe-auth" ||
      window.location.pathname === "/register"
    ) {
      setExpireSubscription(false);
    } else if (isExpire) {
      setExpireSubscription(true);
    }
  }, [expireSubscription, isExpire]);

  const { handleRowClicked } = useDeepSearch();
  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<LoginComponent url={url} />} />
        <Route
          path="/home"
          element={
            <HomeUI
              userData={userData}
              setApiCall={setApiCall}
              setUserData={setUserData}
              apiCall={apiCall}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          path="/"
          element={
            <LandingPage
              userData={userData}
              setUserData={setUserData}
              setApiCall={setApiCall}
              apiCall={apiCall}
              subscription={subscription}
              url={url}
              packages={packages}
            />
          }
        />
        <Route path="/register" element={<SignUpComponent url={url} />} />
        <Route
          path="/authentication"
          element={
            <AuthenticateComponent
              apiCall={apiCall}
              setApiCall={setApiCall}
              userData={userData}
            />
          }
        />
        <Route
          path="/stripe-auth"
          element={
            <StripePaymentauth
              apiCall2={apiCall2}
              setApiCall2={setApiCall2}
              apiCall={apiCall}
              userData={userData}
              setApiCall={setApiCall}
              setUserData={setUserData}
              apiToken={apiToken}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/verify-account"
          element={
            <VerifyAccount
              apiCall={apiCall}
              userData={userData}
              setApiCall={setApiCall}
              setUserData={setUserData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/tiktok-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              userData={userData}
              messageModal={messageModal}
              setMessageModal={setMessageModal}
              messageInfo={messageInfo}
              setMessageInfo={setMessageInfo}
              data={() =>
                Tiktok(userData, setUserData, disabledButton, handleRowClicked)
              }
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/twitter-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() =>
                Twitter(userData, setUserData, disabledButton, handleRowClicked)
              }
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyPolicyComponent
              apiCall={apiCall}
              setApiCall={setApiCall}
              userData={userData}
            />
          }
        />
        <Route
          path="/features"
          element={
            <FeaturesComponent
              apiCall={apiCall}
              setApiCall={setApiCall}
              userData={userData}
            />
          }
        />
        <Route
          path="/about-us"
          element={
            <AboutUsComponent
              apiCall={apiCall}
              setApiCall={setApiCall}
              userData={userData}
            />
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <>
              <HeaderUI
                userData={userData}
                setApiCall={setApiCall}
                apiCall={apiCall}
                setUserData={setUserData}
              />
              <TermsAndConditions
                newPage={true}
                apiCall={apiCall}
                setApiCall={setApiCall}
                userData={userData}
              />
              <FooterSection />
            </>
          }
        />
        <Route
          path="/forget-password"
          element={<ForgetComponent url={url} />}
        />
        <Route
          path="/account-overview"
          element={
            <AccountOverview
              packages={packages}
              intervalTime={intervalTime}
              subscription={subscription}
              url={url}
              userData={userData}
              apiCall={apiCall}
              setUserData={setUserData}
              setApiCall={setApiCall}
              day={day}
              hours={hours}
              mint={mint}
              endDate={endDate}
              seconds={seconds}
              setExpireSubscription={setExpireSubscription}
              setSeconds={setSeconds}
              setMint={setMint}
              setHours={setHours}
              setDay={setDay}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/subscription-plan"
          element={
            <SubscriptionPlan
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
              setApiCall={setApiCall}
              packages={packages}
              subscription={subscription}
              apiCall={apiCall}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/subscription-modal"
          element={
            <PrcingModal
              setApiCall={setApiCall}
              apiCall={apiCall}
              setUserData={setUserData}
              userData={userData}
            />
          }
        />
        <Route
          path="/pricing"
          element={
            <PricingComponent
              setApiCall={setApiCall}
              apiCall={apiCall}
              userData={userData}
            />
          }
        />
        <Route
          path="/stripe-payment"
          element={
            <PaymentComponent
              userData={userData}
              apiCall={apiCall}
              setUserData={setUserData}
              setApiCall={setApiCall}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
            />
          }
        />
        <Route
          path="/spotifyplaylist-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() =>
                SpotifyPlaylists(
                  userData,
                  setUserData,
                  disabledButton,
                  handleRowClicked
                )
              }
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/spotifyartists-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() => SpotifyArtists(disabledButton, handleRowClicked)}
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/sound-cloud-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              disableSocket={loader}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() =>
                SoundCloud(
                  userData,
                  setUserData,
                  disabledButton,
                  upgradeModal,
                  setupgradeModal,
                  loader,
                  setLoader,
                  loaderMsgId,
                  setLoaderMsgId,
                  handleRowClicked
                )
              }
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/spotify-instagram-link-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={SpotifyInstagramUrls}
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/instagram-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              disableSocket={loader}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() =>
                Instagram(
                  userData,
                  setUserData,
                  disabledButton,
                  upgradeModal,
                  setupgradeModal,
                  loader,
                  setLoader,
                  loaderMsgId,
                  setLoaderMsgId,
                  handleRowClicked
                )
              }
              apiToken={apiToken}
              userData={userData}
            />
          }
        />
        <Route
          path="/youtube-dashboard"
          element={
            <Dashboard
              disabledButton={disabledButton}
              setDisabledButton={setDisabledButton}
              isExpire={isExpire}
              userInfo={userInfo}
              apiCall={apiCall}
              setApiCall={setApiCall}
              setUserData={setUserData}
              data={() =>
                Youtube(userData, setUserData, disabledButton, handleRowClicked)
              }
              userData={userData}
              apiToken={apiToken}
            />
          }
        />
        <Route
          path="/authConnect"
          element={<LinkIdModal socailIcon={"tiktok"} linkIdModal={true} />}
        />
      </Routes>
      <UpdateModal
        isShow={expireSubscription}
        apiCall={apiCall}
        setApiCall={setApiCall}
        navigate={navigate}
      />
    </React.Fragment>
  );
}

export default App;
