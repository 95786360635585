import React from "react";

import ImageComponent from "../Components/imageComponent";

import { Tooltip } from "antd";
export default function ProfileComponent({ userData, changePasswordFn }) {
  return (
    <div className="ProfileComponent">
      <div className="user-profile">
        <div className="profile-img-parent">
          <ImageComponent src={userData?.picture} className="profile-img" />
        </div>
        <div className="name-email">
          <div className="profile-name">
            {userData?.user_id?.split("|")[0] === "auth0"
              ? userData?.username
              : userData?.name}
          </div>
          <div className="profile-email ">
            <span>{userData?.email ? userData?.email : userData?.name}</span>
          </div>
          <div className="profile-email ">
            <span>password: ********</span>
            {userData?.identities?.length &&
            userData?.identities[0]?.isSocial ? null : (
              <>
                <Tooltip
                  rootClassName="tooltip-credit-details"
                  placement="top"
                  title={`Request a password reset link`}
                  arrow={true}
                >
                  <img
                    className="profile-email-img"
                    style={{ cursor: "pointer" }}
                    src="./Assets/Images/edit.png"
                    alt="editIcon"
                    width={30}
                    onClick={changePasswordFn}
                  />
                  <img
                    className="profile-email-img-res"
                    style={{ cursor: "pointer" }}
                    src="./Assets/Images/components/edit.png"
                    alt="editIcon"
                    width={30}
                    onClick={changePasswordFn}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
