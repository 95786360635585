import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ButtonComponent from "./buttonComponent";
import FacebookButton from "./facebookButton";
import GoogleButton from "./googleButton";
import InprogressComponent from "./inprogressComponent";
import InputComponent from "./inputComponent";

export default function LoginForm({ handleChange, ...props }) {
  var url = new URL(window.location);
  let redirect = url.searchParams.get("redirect");
  const navigation = useNavigate();
  const location = useLocation().pathname;

  return (
    <div
      className="form-main"
      style={props.loader ? { justifyContent: "center" } : {}}
      // onSubmit={handleChange}
    >
      {/* <span ><span>Back</span></span> */}
      <div
        className="back-arrow-text"
        onClick={() => {
          if (redirect) {
            navigation(`/${redirect}`);
          } else {
            navigation("/");
          }
        }}
        // location == "/register" ?
        // : navigation("/register")
      >
        <div>
          <img src="/Assets/Images/back.png" alt="" />
        </div>
        <div>Back</div>
      </div>
      {/* {!props?.loader && ( */}
      <>
        <div className="text-innerText">
          <div className="header-Text">{props.text}</div>
          {/* <div className="header-inner-text">
            Please fill the form below to recieve a qoute for your project.{" "}
            <br /> Fell free to add detail as much as needed
          </div> */}
        </div>
        <div className="inner-form-main">
          <div className="socail-parent">
            <GoogleButton handleChange={props?.googleLogin} />
            <FacebookButton handleChange={props?.facebookLogin} />
          </div>
          <div className="header-text-center"> Or </div>
          <form style={{ width: "100%" }} onSubmit={handleChange}>
            {props.text === "Register" && (
              <InputComponent
                heading={"Name"}
                value={props?.object?.username}
                classinput={"inputBg"}
                placeholder={"Name"}
                type={"text"}
                icon={""}
                required={true}
                onChange={(ev) =>
                  props?.setObject({
                    ...props?.object,
                    username: ev.target?.value,
                  })
                }
              />
            )}
            <InputComponent
              heading={"Email"}
              value={props?.object?.email}
              classinput={"inputBg"}
              placeholder={"Email address"}
              type={"email"}
              icon={"https://img.icons8.com/ios/50/filled-message.png"}
              required={true}
              onChange={(ev) =>
                props?.setObject({
                  ...props?.object,
                  email: ev.target?.value,
                })
              }
            />
            <InputComponent
              heading={"Password"}
              value={props?.object?.password}
              classinput={"inputBg"}
              placeholder={"Password"}
              type={"password"}
              icon={
                "https://img.icons8.com/material-outlined/24/visible--v1.png"
              }
              // icon= {"https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/external-eye-devices-flatart-icons-outline-flatarticons.png"}
              required={true}
              onChange={(ev) =>
                props?.setObject({
                  ...props?.object,
                  password: ev.target?.value,
                })
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {props.text === "Register" ? (
                <p>
                  Already have an account?{" "}
                  <Link
                    className="account forget-password"
                    to={"/login?redirect=register"}
                  >
                    Log in
                  </Link>
                </p>
              ) : (
                <p>
                  Don't have an account?{" "}
                  <Link
                    className="account forget-password"
                    to={"/register?redirect=login"}
                  >
                    Sign up
                  </Link>
                </p>
              )}
              {props.text !== "Register" && (
                <Link className="forget-password" to={"/forget-password"}>
                  Forget password?
                </Link>
              )}
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                text={props.text || "Login"}
                btnclass="btn-background mb-2"
              />
              {/* <InprogressComponent/> */}
              {props?.loader && (
                <InprogressComponent
                  headerText={
                    props.text === "Register"
                      ? "Creating Your Account"
                      : "Authenticating Your Account"
                  }
                  paraText={"Please wait while the page is loading."}
                  // loader={props.loader}
                />
              )}
            </div>
          </form>
        </div>
      </>
      {/* )} */}
    </div>
  );
}
