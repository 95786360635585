import React from "react";
import SubscriptionCards from "../Components/subscriptionCards";

export default function PricingComponent({
  starter,
  medium,
  pro,
  text,
  navigation,
  picingData,
  handleChange,
  userData,
  select,
  para,
}) {
  return (
    <React.Fragment>
      <div className="SubscriptionPlan pricing-component">
        {!text && (
          <>
            <h1 className="SubscriptionPlan-Header-Text">
              Select a Pricing Plan
            </h1>
            <p className="SubscriptionPlan-Header-para">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </>
        )}
        <div className="SubscriptionCards-Parent">
          <SubscriptionCards
            index={0}
            stripeId={process.env.REACT_APP_STRIPE_PRODUCT_0_ID}
            select={select}
            handleChange={handleChange}
            picingData={picingData}
            plansText={"Free"}
            price={0}
            selectPlan={text || false}
            userData={userData}
            // url={"/Assets/Images/puzzle.png"}
            headertext={"Free"}
            navigation={navigation}
            headerpara={``}
            subscriptionPlans={[
              "250 credits per month",
              "Instagram search",
              "Instagram URL imports feature",
              "Spotify artists search",
              "Spotify playlists search",
              "SoundCloud search",
              "TikTok search",
              "Youtube search",
              "Twitter search",
              "Export of results",
              "Priority support ",
            ]}
            disabled={
              userData?.user_metadata?.freeVersion === "claimed" ? true : false
            }
            confirmPayment={true}
            id={"card1"}
          />
          <SubscriptionCards
            index={1}
            stripeId={
              para === "month"
                ? process.env.REACT_APP_STRIPE_PRODUCT_50_ID
                : process.env.REACT_APP_STRIPE_PRODUCT_100_YEARLY
            }
            select={select}
            handleChange={handleChange}
            picingData={picingData}
            plansText={"Starter"}
            navigation={navigation}
            selectPlan={text || false}
            userData={userData}
            headertext={`€${
              para === "month" ? starter : Number(starter / 12).toFixed(2)
            }`}
            price={starter}
            headerpara={`/ Month`}
            SubscriptionText={"Buy Now"}
            subscriptionPlans={[
              "1000 credits per month",
              "Instagram search",
              "Instagram URL imports feature",
              "Spotify artists search",
              "Spotify playlists search",
              "SoundCloud search",
              "TikTok search",
              "Youtube search",
              "Twitter search",
              "Export of results",
              "Priority support ",
            ]}
            confirmPayment={true}
            id={"card2"}
          />
          <SubscriptionCards
            index={2}
            stripeId={
              para === "month"
                ? process.env.REACT_APP_STRIPE_PRODUCT_100_ID
                : process.env.REACT_APP_STRIPE_PRODUCT_200_YEARLY
            }
            select={select}
            handleChange={handleChange}
            picingData={picingData}
            plansText={"Medium"}
            selectPlan={text || false}
            userData={userData}
            navigation={navigation}
            price={medium}
            headerpara={`/ Month`}
            SubscriptionText={"Buy Now"}
            headertext={`€${
              para === "month" ? medium : (medium / 12).toFixed(2)
            }`}
            subscriptionPlans={[
              "10000 credits per month",
              "Instagram search",
              "Instagram URL imports feature",
              "Spotify artists search",
              "Spotify playlists search",
              "SoundCloud search",
              "TikTok search",
              "Youtube search",
              "Twitter search",
              "Export of results",
              "Priority support ",
            ]}
            confirmPayment={true}
            id={"card3"}
          />
          <SubscriptionCards
            index={3}
            stripeId={
              para === "month"
                ? process.env.REACT_APP_STRIPE_PRODUCT_200_ID
                : process.env.REACT_APP_STRIPE_PRODUCT_400_YEARLY
            }
            select={select}
            handleChange={handleChange}
            picingData={picingData}
            plansText={"Pro"}
            price={pro}
            headertext={`€${
              para === "month" ? pro : Number(pro / 12).toFixed(2)
            }`}
            selectPlan={text || false}
            userData={userData}
            navigation={navigation}
            headerpara={`/ Month`}
            SubscriptionText={"Buy Now"}
            subscriptionPlans={[
              "50000 credits per month",
              "Instagram search",
              "Instagram URL imports feature",
              "Spotify artists search",
              "Spotify playlists search",
              "SoundCloud search",
              "TikTok search",
              "Youtube search",
              "Twitter search",
              "Export of results",
              "Priority support",
            ]}
            confirmPayment={true}
            id={"card4"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
