import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import AuthSwiper from "./authSwiper";
import InprogressComponent from "../Components/inprogressComponent";
import { useAuth0 } from "@auth0/auth0-react";
function VerifyAccount(props) {
  const { logout } = useAuth0();

  const navigate = useNavigate();
  useEffect(() => {
    if (props?.userData?.email_verified) {
      localStorage.setItem("accessToken", localStorage.getItem("token"));
      navigate("/subscription-plan");
    }
  }, [props?.userData]);
  const resendFn = () => {
    if (props?.userData?.email_verified) {
      localStorage.setItem("accessToken", localStorage.getItem("token"));
      navigate("/subscription-plan");
    } else {
      var options = {
        method: "POST",
        url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/jobs/verification-email`,
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${props?.apiToken}`,
        },
        data: {
          user_id: `${props?.userData?.user_id}`,
          client_id: `${process.env.REACT_APP_AUTH0_API_CLIENT_ID}`,
        },
      };
      axios(options)
        .then(function (response) {
          toast.success("A verification mail has been sent to your email");
        })
        .catch(function (error) {
        });
    }
  };
  return (
    <React.Fragment>
      <InprogressComponent
        headerText={"Verifing Email"}
        paraText={"Thank you for verifying the email."}
        loader={true}
      />
      <div className="LoginComponent mainComponent">
        <div className="section-2">
          <div className="form-main">
            <div className="header-Text">Verify Email</div>

            <p>
              your email is not verified from this email:{" "}
              <strong>{props?.userData?.email}.</strong>{" "}
            </p>
            <p>
              If you didn't receive an email yet, please{" "}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0d6efd",
                }}
                onClick={resendFn}
              >
                click here.
              </span>
              or
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0d6efd",
                }}
                onClick={() => {
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  });
                }}
              >
                Logout
              </span>
              <br />
              <br />
              <span style={{ color: "grey", textAlign: "center" }}>
                The verification email might end up in spam when people sign up.
              </span>
            </p>
          </div>
        </div>
        <div className="section-1">
          <div className="auth-slider-component-parent">
            <AuthSwiper />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VerifyAccount;
