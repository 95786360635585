"use client";

import { Tooltip } from "antd";
import { useState } from "react";

export function ExpandableMessageQueue({
  item,
  message,
  isOpen,
  setIsOpen,
  setFilterTextSelect,
}) {
  const [activeTab, setActiveTab] = useState("search"); // Default to search tab
  const [expandedMessages, setExpandedMessages] = useState({});

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) setExpandedMessages({});
  };

  const toggleMessage = (messageId) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [messageId]: !prev[messageId],
    }));
  };

  const filteredItems = activeTab === "message" ? message : item;

  if (!isOpen) {
    return (
      <Tooltip placement="topLeft" title={"Check Queue"} arrow={true}>
        <div className="fixed bottom-4 right-4 z-20">
          <div className="relative">
            <button
              onClick={toggleOpen}
              className="rounded-full w-[65px] h-[65px] btn-background shadow-md text-white flex items-center justify-center"
            >
              <img src="/chat.svg" alt="" className="w-12 h-12 animate-pulse" />
            </button>
            {
              <div className="absolute -top-3 text-white right-0.5 z-30 bg-green-400 w-6 h-6 m-auto text-[15px] text-center flex justify-center rounded-full">
                {
                  filteredItems?.filter((item) =>
                    activeTab === "message"
                      ? item?.status == "In Process"
                      : item?.remarks == "In Process"
                  )?.length
                }
              </div>
            }
          </div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className="fixed z-50 bottom-4 right-4 w-[450px]">
      <button
        onClick={toggleOpen}
        className="w-full btn-background text-white rounded-lg px-4 py-3.5 shadow-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-between"
      >
        <span className="font-semibold">Task Queue</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`h-5 w-5 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      <div className="bg-white rounded-lg shadow-xl overflow-hidden">
        {/* Tabs */}
        <div className="flex border-b border-gray-200">
          <button
            onClick={() => setActiveTab("search")}
            className={`flex-1 py-3 px-4 font-medium text-sm transition-colors ${
              activeTab === "search"
                ? "bg-blue-50 breadcrumb-link-active"
                : "hover:bg-gray-50 text-gray-700"
            }`}
          >
            Search Queue
          </button>
          <button
            onClick={() => setActiveTab("message")}
            className={`flex-1 py-3 px-4 font-medium text-sm transition-colors ${
              activeTab === "message"
                ? "bg-blue-50 breadcrumb-link-active"
                : "hover:bg-gray-50 text-gray-700"
            }`}
          >
            Message Queue
          </button>
        </div>

        <div className="max-h-96 overflow-y-auto">
          {filteredItems?.map((item, index) => (
            <div
              key={item.id}
              className={`border-b border-gray-200 last:border-b-0 ${
                item.remarks == "Completed" && "cursor-pointer"
              }`}
            >
              <div
                className={`p-3 bg-white hover:bg-gray-50`}
                onClick={() => {
                  if (item.remarks == "Completed") {
                    setFilterTextSelect(item?.search);
                    toggleOpen();
                  }
                }}
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium text-lg text-slate-600">
                    {activeTab === "message" ? item.reciever : item?.search}
                    <span className="text-xs text-gray-500 font-medium">
                      ({item?.platform})
                    </span>
                  </span>
                  <span
                    className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(
                      item.remarks
                    )}`}
                  >
                    {activeTab === "message" ? item?.status : item?.remarks}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function getStatusColor(status) {
  switch (status) {
    case "Completed":
      return "bg-green-100 text-green-800";
    case "Error!":
      return "bg-red-100 text-red-800";
    default:
      return "bg-yellow-100 text-yellow-800";
  }
}
