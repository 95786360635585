import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils/Utils";
import InputComponent from "./inputComponent";
import ImageComponent from "./imageComponent";
import InprogressComponent from "./inprogressComponent";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);

export default function StripeComponent({
  apiCall,
  setApiCall,
  userData,
  setUserData,
  picingData,
  handleNext,
  cardDetail,
  expiryMonth,
  expiryDate,
  cardNumber,
  setCardDetail,
  setExpiryMonth,
  setExpiryDate,
  setCardNumber,
  name,
  setName,
  setCouponCode,
  couponCode,
  ...props
}) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        url={props.url}
        userData={userData}
        apiCall={apiCall}
        setApiCall={setApiCall}
        setUserData={setUserData}
        handleNext={handleNext}
        picingData={picingData}
        setName={setName}
        setCardDetail={setCardDetail}
        cardDetail={cardDetail}
        setExpiryMonth={setExpiryMonth}
        expiryMonth={expiryMonth}
        setExpiryDate={setExpiryDate}
        expiryDate={expiryDate}
        setCardNumber={setCardNumber}
        cardNumber={cardNumber}
        setCouponCode={setCouponCode}
        couponCode={couponCode}
      />
    </Elements>
  );
}

export const CheckoutForm = ({
  apiCall,
  setUserData,
  userData,
  setApiCall,
  picingData,
  handleNext,
  cardDetail,
  expiryMonth,
  expiryDate,
  cardNumber,
  setCardDetail,
  setExpiryMonth,
  setExpiryDate,
  setCardNumber,
  name,
  setName,
  setCouponCode,
  couponCode,
  ...props
}) => {
  const loader = false;

  return (
    <form className="form-main form-stripe">
      {loader && (
        <InprogressComponent
          headerText={"Checkout Processing"}
          paraText={"Please wait while the page is loading."}
          loader={loader}
        />
      )}
      {!loader && (
        <>
          <h1 className="SubscriptionPlan-Header-Text">Add a Card</h1>
          <p className="SubscriptionPlan-Header-para">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
          <div className="payment-header">
            <div className="payment-child">
              <ImageComponent
                style={{ marginRight: "10px" }}
                src={"/Assets/Images/Success.png"}
              />

              <h5 className="payment-header-text">Confirm Payment</h5>
            </div>
            <div className="payment-child">
              <img src="/Assets/Images/CardLogo's.png" alt="img" srcset="" />
              {/* <ImageComponent src={"/Assets/Images/cards/CardLogo's.png"} /> */}
              {/* <ImageComponent src={"/Assets/Images/cards/Visa.png"} />
              <ImageComponent src={"/Assets/Images/cards/Troy.png"} /> */}
            </div>
          </div>
          {picingData?.heading !== "Free" ? (
            <>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Card number</h6>
                  <p>Enter the 16-digit card number on the card</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={cardNumber}
                    placeholder="Card number"
                    onChange={(ev) => {
                      let objj = { ...cardDetail };
                      setCardNumber(
                        formatCreditCardNumber(ev.target.value).cardNumber
                      );
                      objj.number = ev.target.value.replace(/\s+/g, "");
                      setCardDetail(objj);
                    }}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Card owner</h6>
                  <p>Enter the name on the card</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={name}
                    placeholder="Owner name"
                    onChange={(ev) => setName(ev.target.value)}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Expiry date</h6>
                  <p>Enter the expration date of the card</p>
                </div>
                <div className="form-child-section-2">
                  <div className="expire-parent-1 ">
                    <InputComponent
                      style={{ width: "56px" }}
                      classinput="input-Stripe"
                      value={expiryMonth}
                      placeholder="mm"
                      type={"number"}
                      max={2}
                      onChange={(ev) => {
                        let objj = { ...cardDetail };
                        setExpiryMonth(formatExpirationDate(ev.target.value));
                        objj.exp_month = Number(ev.target.value);
                        setCardDetail(objj);
                      }}
                    />
                    <div className="expire-Line">/</div>
                    <InputComponent
                      max={2}
                      style={{ width: "56px" }}
                      classinput="input-Stripe"
                      value={expiryDate}
                      placeholder="YY"
                      type={"number"}
                      onChange={(ev) => {
                        let objj = { ...cardDetail };
                        setExpiryDate(formatExpirationDate(ev.target.value));
                        objj.exp_year = Number(ev.target.value);
                        setCardDetail(objj);
                      }}
                    />
                  </div>
                  <div className="expire-parent-2 security-none">
                    <div className="form-child-section-1">
                      <h6>CVV2</h6>
                      <p style={{ margin: "0px" }}>Security code</p>
                    </div>
                    <div className="form-child-CCV">
                      <InputComponent
                        max={3}
                        classinput="input-Stripe"
                        value={cardDetail.cvc}
                        onChange={(ev) => {
                          let objj = { ...cardDetail };
                          objj.cvc = formatCVC(ev.target.value);
                          setCardDetail(objj);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-main-child res-security-block">
                <div className="form-child-section-1">
                  <h6>CVV2</h6>
                  <p>Security code</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={cardDetail.cvc}
                    onChange={(ev) => {
                      let objj = { ...cardDetail };
                      objj.cvc = formatCVC(ev.target.value);
                      setCardDetail(objj);
                    }}
                  />
                </div>
              </div>
              <div className="form-main-child">
                <div className="form-child-section-1">
                  <h6>Coupen code</h6>
                  <p>Enter the code copen (if any)</p>
                </div>
                <div className="form-child-section-2">
                  <InputComponent
                    classinput="input-Stripe"
                    value={couponCode}
                    placeholder="coupen code"
                    onChange={(ev) => setCouponCode(ev.target.value)}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="form-main-child">
              <div className="form-child-section-1">
                <h6>Email</h6>
                {/* <p>Enter the 16-digit card number on the card</p> */}
              </div>
              <div className="form-child-section-2">
                <InputComponent
                  classinput="input-Stripe"
                  value={userData?.email}
                  disabled={true}
                  placeholder="email"
                />
              </div>
            </div>
          )}
          <div className="border-Line"></div>
        </>
      )}
      {/* <ModalDialog
          confirmLoader={confirmLoader}
        handleClose={() => setmodalOpen(false)}
        subscriptionPlan={subscriptionPlan}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        isShow={modalOpen}
      /> */}
    </form>
  );
};
