import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import HorizontalStepper from "../Components/HorizontalStepper";
import HeaderUI from "./HeaderUI";
import { toast } from "react-toastify";
function StripePaymentauth({
  userData,
  setApiCall2,
  setApiCall,
  apiCall,
  setUserData,
  apiToken,
  isAuthenticated,
  isLoading,
}) {
  const stripe = require("stripe")(process.env.REACT_APP_STRIPE_CLIENT_SECRET);
  const navigation = useNavigate();
  var url = new URL(window.location);
  let select = url.searchParams.get("select");
  let redirect = url.searchParams.get("redirect");
  let open = url.searchParams.get("open");
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      if (!localStorage.getItem("payment-intent")) {
        if (select === "free") {
        } else {
          navigation("/home");
        }
      }
    }
  }, [userData, isAuthenticated, isLoading]);
  useEffect(() => {
    if (userData?.user_id) {
      if (apiToken !== "") {
        if (userData && localStorage.getItem("payment-intent")) {
          stripe.subscriptions
            .list({
              customer: userData?.app_metadata?.stripe_customer_id,
            })
            .then((subscriptions) => {
              let data;
              data = subscriptions.data[0];
              stripe.checkout.sessions
                .retrieve(localStorage.getItem("payment-intent"))
                .then((response) => {
                  if (response?.status === "complete") {
                    stripe.prices
                      .retrieve(response?.metadata?.productId)
                      .then((res) => {
                     
                        let user_metadata =
                          res?.plan?.interval === "year" ||
                          res?.recurring?.interval === "year"
                            ? userData?.user_metadata?.subscriptionPlan
                              ? {
                                  subscriptionPlan:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? "Starter"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Medium"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Pro"
                                      : null,
                                  remainingCredits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        ) +
                                        userData?.user_metadata
                                          ?.remainingCredits
                                      : null,
                                  credits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        ) + userData?.user_metadata?.credits
                                      : null,
                                  exp_date: data?.current_period_start,
                                }
                              : {
                                  subscriptionPlan:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? "Starter"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Medium"
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? "Pro"
                                      : null,
                                  remainingCredits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        )
                                      : null,
                                  credits:
                                    Number(
                                      process.env
                                        .REACT_APP_STRIPE_PRICE_STARTER_Yearly
                                    ) ===
                                    res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_STARTER_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_MEDIUM_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_MEDIUM_Yearly
                                        )
                                      : Number(
                                          process.env
                                            .REACT_APP_STRIPE_PRICE_PRO_Yearly
                                        ) ===
                                        res?.unit_amount / 100
                                      ? Number(
                                          process.env
                                            .REACT_APP_STRIPE_CREDITS_PRO_Yearly
                                        )
                                      : null,
                                  exp_date: data?.current_period_start,
                                }
                            : userData?.user_metadata?.subscriptionPlan
                            ? {
                                subscriptionPlan:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? "Starter"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Medium"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Pro"
                                    : null,
                                remainingCredits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      ) +
                                      userData?.user_metadata?.remainingCredits
                                    : null,
                                credits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      ) + userData?.user_metadata?.credits
                                    : null,
                                exp_date: data?.current_period_start,
                              }
                            : {
                                subscriptionPlan:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? "Starter"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Medium"
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? "Pro"
                                    : null,
                                remainingCredits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      )
                                    : null,
                                credits:
                                  Number(
                                    process.env
                                      .REACT_APP_STRIPE_PRICE_STARTER_MONTHLY
                                  ) ===
                                  res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_STARTER_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_MEDIUM_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_MEDIUM_MONTHLY
                                      )
                                    : Number(
                                        process.env
                                          .REACT_APP_STRIPE_PRICE_PRO_MONTHLY
                                      ) ===
                                      res?.unit_amount / 100
                                    ? Number(
                                        process.env
                                          .REACT_APP_STRIPE_CREDITS_PRO_MONTHLY
                                      )
                                    : null,
                                exp_date: data?.current_period_start,
                              };
                        var options = {
                          method: "PATCH",
                          url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
                          headers: {
                            authorization: `Bearer ${apiToken}`,
                            "content-type": "application/json",
                          },
                          data: {
                            user_metadata: {
                              ...user_metadata,
                              token: apiToken,
                            },
                          },
                        };
                        axios
                          .request(options)
                          .then(function (response) {
                            setUserData(response.data);
                            localStorage.removeItem("payment-intent");
                            if (open === "message") {
                              if (
                                response.data?.user_metadata
                                  ?.subscriptionPlan === "Pro"
                              ) {
                                navigation(
                                  redirect
                                    ? `/${redirect}?open=${open}`
                                    : "/home"
                                );
                              } else {
                                navigation(redirect ? `/${redirect}` : "/home");
                              }
                            } else if (open === "PDF" || open === "CSV") {
                              if (
                                response.data?.user_metadata
                                  ?.subscriptionPlan !== "Free"
                              ) {
                                navigation(
                                  redirect
                                    ? `/${redirect}?open=${open}`
                                    : "/home"
                                );
                              } else {
                                navigation(redirect ? `/${redirect}` : "/home");
                              }
                            } else {
                              navigation(redirect ? `/${redirect}` : "/home");
                            }
                            setApiCall2(true);
                          })
                          .catch(function (error) {
                          
                  
                            if (
                              error?.response?.data?.error === "Unauthorized"
                            ) {
                              var options = {
                                method: "post",
                                url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/oauth/token`,
                                headers: { "content-type": "application/json" },
                                data: `{"client_id":"${process.env.REACT_APP_AUTH0_API_CLIENT_ID}","client_secret":"${process.env.REACT_APP_AUTH0_API_CLIENT_SECRET}","audience":"${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/","grant_type":"client_credentials"}`,
                              };
                              axios(options)
                                .then((res) => {
                                  var options = {
                                    method: "PATCH",
                                    url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
                                    headers: {
                                      authorization: `Bearer ${res.data?.access_token}`,
                                      "content-type": "application/json",
                                    },
                                    data: {
                                      user_metadata: {
                                        ...user_metadata,
                                        token: res.data?.access_token,
                                      },
                                    },
                                  };
                                  axios
                                    .request(options)
                                    .then(function (response) {
                                      setUserData(response.data);
                                      localStorage.removeItem("payment-intent");
                                      if (open === "message") {
                                        if (
                                          response.data?.user_metadata
                                            ?.subscriptionPlan === "Pro"
                                        ) {
                                          navigation(
                                            redirect
                                              ? `/${redirect}?open=${open}`
                                              : "/home"
                                          );
                                        } else {
                                          navigation(
                                            redirect ? `/${redirect}` : "/home"
                                          );
                                        }
                                      } else if (
                                        open === "PDF" ||
                                        open === "CSV"
                                      ) {
                                        if (
                                          response.data?.user_metadata
                                            ?.subscriptionPlan !== "Free"
                                        ) {
                                          navigation(
                                            redirect
                                              ? `/${redirect}?open=${open}`
                                              : "/home"
                                          );
                                        } else {
                                          navigation(
                                            redirect ? `/${redirect}` : "/home"
                                          );
                                        }
                                      } else {
                                        navigation(
                                          redirect ? `/${redirect}` : "/home"
                                        );
                                      }
                                      setApiCall2(true);
                                    })
                                    .catch(function (error) {
                                    });
                                })
                                .catch(function (error) {
                                  console.error(error);
                                });
                            }
                          });
                      })
                      .catch((err) => console.log(err, "err"));
                  }
                })
                .catch((err) => console.log(err, "err"));
            })
            .catch((err) => console.log(err, "err"));
        } else if (select === "free") {
          let user_metadata = userData?.user_metadata?.subscriptionPlan
            ? {
                subscriptionPlan: "Free",
                remainingCredits:
                  Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                  userData?.user_metadata?.remainingCredits,
                credits:
                  Number(process.env.REACT_APP_STRIPE_CREDITS_FREE) +
                  userData?.user_metadata?.credits,
                freeVersion: "claimed",
              }
            : {
                subscriptionPlan: "Free",
                remainingCredits: Number(
                  process.env.REACT_APP_STRIPE_CREDITS_FREE
                ),
                credits: Number(process.env.REACT_APP_STRIPE_CREDITS_FREE),
                freeVersion: "claimed",
              };

          var options = {
            method: "PATCH",
            url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
            headers: {
              authorization: `Bearer ${apiToken}`,
              "content-type": "application/json",
            },
            data: {
              user_metadata: {
                ...user_metadata,
                token: apiToken,
              },
            },
          };
          axios
            .request(options)
            .then(function (response) {
              setUserData(response.data);
              setApiCall2(true);
              navigation("/home");
            })
            .catch(function (error) {
              navigation("/subscription-plan");
              toast.error("invalid Error");
            });
        }
      }
    }
  }, [userData, localStorage.getItem("payment-intent"), apiToken]);
  return (
    <React.Fragment>
      <HeaderUI
        userData={userData}
        setApiCall={setApiCall}
        setUserData={setUserData}
        apiCall={apiCall}
      />
      <div className="SubscriptionPlan">
        <HorizontalStepper
          userData={userData}
          setApiCall={setApiCall}
          apiCall={apiCall}
          setUserData={setUserData}
          stepsNumber={2}
        />
      </div>
    </React.Fragment>
  );
}

export default StripePaymentauth;
