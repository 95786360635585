import React from "react";
import ButtonComponent from "./buttonComponent";
import ImageComponent from "./imageComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { useAuth0 } from "@auth0/auth0-react";

export default function SubscriptionCards(props) {
  const location = useLocation().pathname;
  const navigation = useNavigate();
  const { loginWithRedirect } = useAuth0();
  return (
    // <div className="card-border">
    <div
      className={
        props.picingData?.index === props.index
          ? "SubscriptionCards SubscriptionCards-Active"
          : "SubscriptionCards"
      }
      id={props.id}
      onClick={() => {
        // if (props.select) {
        // }
      }}
    >
      <div className="card-border">
        <div
          className="cards-section-1"
          style={props.confirmPayment ? { paddingRight: "0px" } : {}}
        >
          <div className="plans-tag">{props.plansText}</div>
          {/* <ImageComponent src={props.url} className="img-plans-tag" /> */}
          <div className="profile-subscription-header-parent">
            <div className="subscription-header-text">{props.headertext}</div>
            {props.headerpara}
          </div>
          <p className="subscription-header-para">
            {props.paragraphText.split("subscription").map((ev, i) => {
              return (
                <>
                  {ev !== "." && ev}
                  {ev === "." && <br />}
                  {ev === "." && "subscription."}
                </>
              );
            })}
          </p>
        </div>

        <div className="cards-section-2">
          {!props.confirmPayment && (
            <ButtonComponent
              btnclass="btn-background"
              text={props.SubscriptionText}
              onClick={() => {
                if (props.SubscriptionText !== "Claimed") {
                  props.handleChange(props?.id, props.price);
                }
              }}
            />
          )}
          <p className="subscriptionPlans-header">Includes:</p>
          <div
            className={props.confirmPayment ? "subscriptionPlans-parent" : ""}
          >
            {props?.subscriptionPlans?.map((plan, index) => {
              let no =
                props.plansText === "Free"
                  ? props?.subscriptionPlans?.length - 3
                  : props.plansText === "Starter"
                  ? props?.subscriptionPlans?.length - 2
                  : props.plansText === "Medium"
                  ? props?.subscriptionPlans?.length - 1
                  : 0;
              return (
                <div
                  key={index}
                  className={`subscriptionPlans-list ${
                    no !== 0 ? (no <= index ? "" : "") : ""
                  }`}
                >
                  {no !== 0 ? (
                    no <= index ? (
                      <div className="img-square">❌</div>
                    ) : (
                      <ImageComponent
                        className="img-square"
                        src="/Assets/Images/tick-square.png"
                      />
                    )
                  ) : (
                    <ImageComponent
                      className="img-square"
                      src="/Assets/Images/tick-square.png"
                    />
                  )}
                  {index === 0 ? (
                    <Tooltip
                      rootClassName="tooltip-credit-details"
                      // className="tooltip-credit-details"
                      placement="bottomLeft"
                      title={`Credits are used to generate the contact information of your desired leads. It costs 1 credit for each Email address, Instagram link, and TikTok link.`}
                      arrow={true}
                    >
                      {plan}
                    </Tooltip>
                  ) : (
                    plan
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {props?.selectPlan && (
          <div className="Select-Plan-parent">
            <ButtonComponent
              text={props?.disabled ? "Claimed" : "Select Plan"}
              btnclass="btn-background mb-2 Select-Plan-btn custom-rounded"
              onClick={() => {
                if (location === "/subscription-plan") {
                  if (props?.disabled) {
                  } else {
                    props.handleChange(
                      props.stripeId,
                      props.price,
                      props.index,
                      props.plansText
                    );
                  }
                } else if (props?.userData?.user_id) {
                  navigation("/subscription-plan");
                } else {
                  // navigation("/login");
                  loginWithRedirect();
                }
              }}
            />
          </div>
        )}
        {/* </div> */}
      </div>
    </div>
  );
}
