import axios from "axios";
import { w3cwebsocket } from "websocket";
export function getPreviousData(prevUrl, setTableData, setPending) {
  setPending(true);
  axios
    .post(`${process.env.REACT_APP_API_URL}${prevUrl}`)
    .then((response) => {
      setTableData(response.data);
    })
    .catch((error) => {})
    .finally(() => setPending(false));
}

export async function connectToWebsocket(
  websocketUrl,
  prevClient,
  setClient,
  setTableData,
  setPending,
  search,
  result_type = null,
  userData,
  setUserData,
  deepSearch
) {
  setPending(true);
  prevClient?.close();
  const client = w3cwebsocket(websocketUrl);
  let count = "";
  count = userData?.user_metadata?.remainingCredits;
  client.onopen = () => {
    setClient(client);
    client.send(
      JSON.stringify({
        user: userData?.username,
        search,
        result_type,
        deep_search: deepSearch,
      })
    );
  };
  client.onmessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    setTableData(dataFromServer);
    setPending(false);
    showCredits(Number(count), setUserData);
  };
  client.onclose = () => {
    setPending(false);
  };
  client.onerror = (e) => {
    setPending(false);
  };
}

export function disconnectFromWebsocket(
  client,
  setDisabledButton,
  setSearch,
  setFilterText,
  disabled
) {
  client?.close();
  setDisabledButton(false);
  setFilterText("");
  setSearch("");
  if (!disabled) {
    document.getElementById("form-Submit-Search").reset();
  }
}

export const showCredits = (
  remainingCredits,
  setUserData,
  client,
  userData,
  apiToken
) => {
  
  var options = {
    method: "PATCH",
    url: `${process.env.REACT_APP_AUTH0_API_DOMAIN}/api/v2/users/${userData?.user_id}`,
    headers: {
      authorization: `Bearer ${apiToken}`,
      "content-type": "application/json",
    },
    data: {
      user_metadata: {
        remainingCredits: remainingCredits,
        token: apiToken,
      },
    },
  };
  axios
    .request(options)
    .then(function (response) {
      setUserData(response.data);
    })
    .catch(function (error) {
    });
};
