import React from "react";

export function YoutubeFollowerRange({
  disabledButton,
  filterObj,
  setFilterObj,
}) {
  return (
    <div style={{ marginTop: 16 }}>
      <div style={{ display: "flex", gap: 27, flexDirection: "column" }}>
        <p className="heading">Number of subscribers</p>
      </div>
      <div style={{ display: "flex", gap: 17 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
            color: "#878787",
            fontSize: "14px",
          }}
        >
          <label>Min</label>
          <input
            type="number"
            disabled={disabledButton}
            value={filterObj?.subscribers || ""}
  
            onChange={(ev) => {
              const numericValue = Number(ev.target.value);
              if (!isNaN(numericValue) && numericValue >= 0) {
                setFilterObj({
                  ...filterObj,
                  subscribers: numericValue,
                });
              }
            }}
            placeholder="0"
            className="filter-menu-input"
          />
        </div>
      </div>
    </div>
  );
}

export function YoutubeShouldBe({ filterObj, setFilterObj }) {
  const buttonConfig = [
    { label: "Email", key: "email" },
    { label: "Instagram", key: "instagram" },
    // { label: "Twitter", key: "twitter" },
    // { label: "Facebook", key: "facebook" },
    // { label: "TikTok", key: "tiktok" },
    // { label: "SoundCloud", key: "soundcloud" },
    // { label: "Spotify", key: "spotify" },
    // { label: "Other Links", key: "other_links" },
  ];

  const handleButtonClick = (key) => {
    setFilterObj({
      ...filterObj,
      [key]: !filterObj?.[key],
    });
  };
  return (
    <div style={{ display: "flex", gap: 17, flexWrap: "wrap", marginTop: 16 }}>
      {buttonConfig.map(({ label, key }) => (
        <button
          key={key}
          className={
            filterObj?.[key]
              ? "btn-background account-range-select apply"
              : "account-range"
          }
          onClick={() => handleButtonClick(key)}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
